import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Tooltip from './Tooltip';

export default class InformationTooltip extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip(e) {
    e.stopPropagation();
    e.preventDefault();

    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
    return (
      <span
        className="information-tooltip"
        onMouseEnter={this.toggleTooltip}
        onMouseLeave={this.toggleTooltip}
        onTouchStart={this.toggleTooltip}
      >
        <i className="fa fa-question-circle" />
        <Tooltip show={this.state.visible}>
          <div className="information-tooltip-container">
            {this.props.children}
          </div>
        </Tooltip>
      </span>
    );
  }
}

InformationTooltip.propTypes = {
  children: PropTypes.node
};
