import React, { useState, useEffect } from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';

const DatePicker = (props) => {
  const [pickerStartDate, setPickerStartDate] = useState(props.startDate);
  const [pickerEndDate, setPickerEndDate] = useState(props.endDate);
  const [focusedInput, setFocused] = useState(false);

  useEffect(() => {
    if (pickerStartDate !== props.startDate) {
      setPickerStartDate(props.startDate);
    }
    if (pickerEndDate !== props.endDate) {
      setPickerEndDate(props.endDate);
    }
  }, [pickerStartDate, pickerEndDate]);

  const handleFocusChange = (fI) => {
    setFocused(fI);
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setPickerStartDate(startDate);
    setPickerEndDate(endDate);
    if (startDate && endDate) {
      props.handleDateChange(startDate, endDate);
    }
  };

  const isOutsideRange = () => false;

  return (
    <div className="datepicker-container">
      <DateRangePicker
        startDate={pickerStartDate}
        startDateId={`${pickerStartDate}-start`}
        endDate={pickerEndDate}
        endDateId={`${pickerEndDate}-end`}
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        onFocusChange={handleFocusChange}
        minimumNights={0}
        isOutsideRange={isOutsideRange}
      />
    </div>
  );
};

DatePicker.propTypes = {
  handleDateChange: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired
};

export default DatePicker;
