/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Map } from 'immutable';
import './MonthList.scss';
import './Buttons.scss';
import Spinner from './Spinner';
import Month from './Month';
import LeftPane from './LeftPane/LeftPaneContainer';
import Calendar from './Calendar';
import BigError from './BigError';

export default class MonthList extends PureComponent {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll(e) {
    if (e.target.scrollTop === 0) {
      if (e.target.scrollTop === 0) this.props.fetchFutureHours();
    } else if (
      e.target.scrollTop ===
      e.target.scrollHeight - e.target.offsetHeight
    ) {
      if (e.target.scrollTop === e.target.scrollHeight - e.target.offsetHeight)
        this.props.fetchPastHours();
    }
  }

  componentDidMount() {
    this.mainColumn.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    this.mainColumn.addEventListener('scroll', this.handleScroll);
  }

  createFetchMoreButton(type, fetching, error, onClick) {
    let result = null;

    if (fetching) {
      result = <Spinner />;
    } else if (error) {
      result = [
        <BigError
          key={`load-more-error-${type}`}
          description="Could not load data"
          text={error.get('error')}
        />,
        <button
          key={`load-more-retry-${type}`}
          className="save-button"
          onClick={onClick}
        >
          Retry
        </button>
      ];
    } else {
      result = (
        <button className="save-button" onClick={onClick}>
          Load more
        </button>
      );
    }
    return result;
  }

  render() {
    const spinnerContainer = (
      <div className="spinner-container">
        <Spinner />
      </div>
    );
    let children;
    const { hours } = this.props;
    const months = hours.getIn(['data', 'months'], Map());
    let monthsLoaded = false;

    if (hours.get('fetching') && !hours.get('data')) {
      children = spinnerContainer;
    } else if (months.size > 0) {
      monthsLoaded = true;
      const scrollAnchor = hours.get('scrollAnchor');
      const scrollAnchorTopAlign = hours.get('scrollAnchorAlignTop');
      children = months
        .entrySeq()
        .sort((a, b) => {
          // sort to descending order
          if (a[0] < b[0]) {
            return 1;
          }
          return -1;
        })
        .map(([key, month]) => (
          <Month
            key={key}
            date={key}
            month={month}
            scrollAnchor={scrollAnchor}
            scrollAnchorAlignTop={scrollAnchorTopAlign}
            scrolledToAnchor={this.props.scrolledToAnchor}
            scrollToDay={this.props.scrollToDay}
          />
        ))
        // Fun fact: Immutable array like structures need to be converted to
        // JS if you create refs to components and use the Immutable structure in
        // render method. And of course this only breaks in dist, and not in dev mode ^___^.
        // Error thrown: https://facebook.github.io/react/docs/error-decoder.html?invariant=119
        // Full error: https://facebook.github.io/react/warnings/refs-must-have-owner.html
        .toArray();
    } else if (this.props.hours.get('fetchError')) {
      const error = this.props.hours.get('fetchError');
      if (error.get('status') === 403) {
        children = (
          <BigError description="You are not authorized to use this service :(" />
        );
      } else {
        children = (
          <BigError
            description="Could not load data"
            text={error.get('error')}
          />
        );
      }
    } else {
      children = <BigError description="List of months was empty" />;
    }

    const fetchFuture = this.createFetchMoreButton(
      'future',
      this.props.hours.get('fetchingFuture'),
      this.props.hours.get('fetchFutureError'),
      this.props.fetchFutureHours
    );

    const fetchPast = this.createFetchMoreButton(
      'past',
      this.props.hours.get('fetchingPast'),
      this.props.hours.get('fetchPastError'),
      this.props.fetchPastHours
    );

    const isSupervisor = (this.props.teamMembers.get('data') || []).size > 0;

    return (
      <main
        className="month-list"
        id="month-list"
        ref={(node) => {
          this.mainColumn = node;
        }}
      >
        <div className="content">
          <div
            className={`column left${!isSupervisor ? ' hidden-tablet' : ''}`}
          >
            <LeftPane />
          </div>
          <div className="main-column">
            <div className={`load-more${!monthsLoaded ? ' hidden' : ''}`}>
              {fetchFuture}
            </div>
            {children}
            <div className={`load-more${!monthsLoaded ? ' hidden' : ''}`}>
              {fetchPast}
            </div>
          </div>
          <div className={`column${isSupervisor ? ' hidden-tablet' : ''}`}>
            <Calendar
              scrollToDay={this.props.scrollToDay}
              fetchFutureHours={this.props.fetchFutureHours}
              fetchPastHours={this.props.fetchPastHours}
              hours={this.props.hours}
              user={this.props.user}
              uploadHolidays={this.props.uploadHolidays}
              uploadOverworkReport={this.props.uploadOverworkReport}
              uploadOvertimeDeltas={this.props.uploadOvertimeDeltas}
              uploadHours={this.props.uploadHours}
              uploader={this.props.uploader}
            />
          </div>
        </div>
      </main>
    );
  }
}

MonthList.propTypes = {
  hours: PropTypes.instanceOf(Map).isRequired,
  fetchFutureHours: PropTypes.func.isRequired,
  fetchPastHours: PropTypes.func.isRequired,
  scrolledToAnchor: PropTypes.func.isRequired,
  scrollToDay: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map),
  teamMembers: PropTypes.instanceOf(Map),
  uploadHolidays: PropTypes.func.isRequired,
  uploadHours: PropTypes.func.isRequired,
  uploadOverworkReport: PropTypes.func.isRequired,
  uploadOvertimeDeltas: PropTypes.func.isRequired,
  uploader: PropTypes.instanceOf(Map).isRequired
};
