/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Map } from 'immutable';
import ReadOnlyInput from './ReadOnlyInput';

import './NativeSelect.scss';

export default class MobileDateSelect extends PureComponent {
  render() {
    return (
      <DayPickerInput
        className="native-select"
        name="end-date"
        placeholder="YYYY-MM-DD"
        format="YYYY-MM-DD"
        value={this.props.value}
        onDayChange={this.props.onChange}
        component={ReadOnlyInput}
        dayPickerProps={this.props.dayPickerProps.toJS()}
      />
    );
  }
}

MobileDateSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  dayPickerProps: PropTypes.instanceOf(Map).isRequired,
  className: PropTypes.string
};
