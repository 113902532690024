import React from 'react';
import PropTypes from 'prop-types';

const HideReasons = ({ handleHideReasonsChange, hideReasons }) => {
  return (
    <div>
      <input
        id="hideReasons"
        type="checkbox"
        onChange={handleHideReasonsChange}
        checked={hideReasons}
      />
      <label htmlFor="hideReasons" className="searchbar--label">
        Hide reasons
      </label>
    </div>
  );
};

HideReasons.propTypes = {
  handleAbsenceFilterChange: PropTypes.func.isRequired,
  showOnlyAbsences: PropTypes.bool
};

export default HideReasons;
