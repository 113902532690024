/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import moment from 'moment';
import './DayShow.scss';
import EntryShow from '../Entry/EntryShow';
import CircleButton from '../CircleButton';

export default class DayShow extends PureComponent {
  constructor() {
    super();

    this.isHoliday = this.isHoliday.bind(this);
    this.isWeekend = this.isWeekend.bind(this);
    this.scrollToDay = this.scrollToDay.bind(this);
    this.editDay = this.editDay.bind(this);
  }

  editDay() {
    this.props.editDay(this.props.user);
  }

  isHoliday() {
    const type = this.props.day.get('type');
    return typeof type === 'string' ? type : false;
  }

  isWeekend() {
    return this.props.day.get('type') === true;
  }

  isToday() {
    return moment(this.props.day).isSame(moment(), 'day');
  }

  scrollToDay() {
    if (this.props.day.get('scrollToDay')) {
      this.scrollAnchor.scrollIntoView();
      this.props.scrolledToDay();
      document.getElementById('month-list').scrollTop -= 65;
    }
  }

  componentDidMount() {
    if (this.props.date === moment().format('YYYY-MM-DD')) {
      this.props.scrollToDay(this.props.date);
      this.dayElement.focus();
    }
    this.scrollToDay();
  }

  componentDidUpdate() {
    this.scrollToDay();
  }

  render() {
    const hours = this.props.day.get('hours', 0);
    let right = null;

    if (this.props.day.get('missingEntries') && !this.props.day.get('closed')) {
      right = <CircleButton positive />;
    } else if (hours !== 0) {
      // Don't render zero hours
      right = hours;
    }

    let entries = this.props.day
      .get('entries')
      .map((entry, i) => {
        let leftSlot = '';
        let rightSlot = '';
        if (i === 0) {
          leftSlot = this.props.date;
          rightSlot = right;
        }
        return (
          <EntryShow
            key={`${this.props.date}-entry-show-${i}`}
            disabled={
              (entry.get('closed') === true ||
                this.props.day.get('closed') === true) &&
              !this.props.user.get('hrLarp')
            }
            date={leftSlot}
            hours={entry.get('hours')}
            projectName={entry.get('projectName')}
            taskName={entry.get('taskName')}
            description={entry.get('description')}
          >
            {rightSlot}
          </EntryShow>
        );
      })
      .toArray();

    if (entries.length === 0) {
      entries = (
        <EntryShow
          date={this.props.date}
          projectName={this.props.day.get('type')}
          disabled={
            this.props.day.get('closed') === true &&
            !this.props.user.get('hrLarp')
          }
        >
          {right}
        </EntryShow>
      );
    }

    let dayColorClass = '';
    if (this.isHoliday()) {
      dayColorClass = ' holiday';
    }
    if (this.isWeekend()) {
      dayColorClass = ' weekend';
    }
    const disabled =
      this.props.day.get('closed') === true && !this.props.user.get('hrLarp')
        ? ' disabled'
        : '';
    const disabledStyle =
      this.props.day.get('closed') === true ? ' disabled' : '';

    let title = '';
    if (this.isWeekend()) {
      title = 'Weekend';
    }
    if (this.isHoliday()) {
      title = 'Public holiday';
    }
    if (disabled) {
      title = 'Editing disabled';
    }

    return (
      <div
        className={`day-show${dayColorClass}${disabledStyle}`}
        onClick={this.editDay}
        title={title}
        onKeyPress={this.editDay}
        tabIndex={0}
        ref={(div) => {
          this.dayElement = div;
        }}
      >
        <div
          className="scroll-anchor"
          ref={(node) => {
            this.scrollAnchor = node;
          }}
        />
        {entries}
      </div>
    );
  }
}

DayShow.propTypes = {
  date: PropTypes.string.isRequired,
  day: PropTypes.instanceOf(Map).isRequired,
  editDay: PropTypes.func.isRequired,
  scrollToDay: PropTypes.func.isRequired,
  scrolledToDay: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map).isRequired
};
