/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import { connect } from 'react-redux';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Map, List } from 'immutable';

import DayShow from './DayShow';
import DayEdit from './DayEdit';
import {
  editDay,
  cancelEditDay,
  saveDay,
  addEntry,
  editEntry,
  deleteEntry,
  deleteDayEditScrollAnchor,
  scrolledToDay,
  scrollToDay
} from '../../reducers/day';

export class Day extends PureComponent {
  render() {
    if (this.props.day.get('edit')) {
      return (
        <DayEdit
          date={this.props.date}
          months={this.props.hours.get('data').get('months')}
          holidays={this.props.holidays}
          day={this.props.day.get('edit')}
          addEntry={this.props.addEntry}
          editEntry={this.props.editEntry}
          deleteEntry={this.props.deleteEntry}
          cancelEditDay={this.props.cancelEditDay}
          saveDay={this.props.saveDay}
          deleteScrollAnchor={this.props.deleteScrollAnchor}
          scrollToDay={this.props.day.get('scrollToDay')}
          scrolledToDay={this.props.scrolledToDay}
          user={this.props.user}
        />
      );
    }
    return (
      <DayShow
        date={this.props.date}
        day={this.props.day}
        editDay={this.props.editDay}
        scrolledToDay={this.props.scrolledToDay}
        scrollToDay={this.props.scrollToDay}
        user={this.props.user}
      />
    );
  }
}

Day.propTypes = {
  day: PropTypes.instanceOf(Map).isRequired,
  hours: PropTypes.instanceOf(Map).isRequired,
  holidays: PropTypes.instanceOf(List),
  date: PropTypes.string.isRequired,
  editDay: PropTypes.func.isRequired,
  cancelEditDay: PropTypes.func.isRequired,
  saveDay: PropTypes.func.isRequired,
  addEntry: PropTypes.func.isRequired,
  editEntry: PropTypes.func.isRequired,
  deleteEntry: PropTypes.func.isRequired,
  deleteScrollAnchor: PropTypes.func.isRequired,
  scrolledToDay: PropTypes.func.isRequired,
  scrollToDay: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map).isRequired
};

export default connect(
  (state, props) => ({
    hours: state.get('hours'),
    date: props.date,
    day: props.day,
    projects: state.getIn(['hours', 'data', 'projects']),
    user: state.getIn(['user', 'data']),
    holidays: state.getIn(['holidays', 'data'])
  }),
  (dispatch, props) => ({
    editDay(user) {
      if (!props.day.get('closed') || user.get('hrLarp')) {
        dispatch(editDay(props.date));
      }
    },
    cancelEditDay() {
      dispatch(cancelEditDay(props.date));
    },
    saveDay() {
      dispatch(saveDay(props.date));
    },
    addEntry() {
      dispatch(addEntry(props.date));
    },
    editEntry(id, entry) {
      dispatch(editEntry(props.date, id, entry));
    },
    deleteEntry(id) {
      dispatch(deleteEntry(props.date, id));
    },
    deleteScrollAnchor() {
      dispatch(deleteDayEditScrollAnchor(props.date));
    },
    scrolledToDay() {
      dispatch(scrolledToDay(props.date));
    },
    scrollToDay(date) {
      dispatch(scrollToDay(date));
    }
  })
)(Day);
