import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './styles.scss';

const TimelineNavigation = ({ handleDateChange, startTime, endTime }) => {
  const nextMonth = () => {
    const nextMonth = moment(startTime).add(1, 'month');

    const start = nextMonth.startOf('month').format('YYYY-MM-DD');
    const end = nextMonth.endOf('month').format('YYYY-MM-DD');

    handleDateChange(start, end);
  };

  const zoomThisWeek = () => {
    const today = moment();

    const start = today.startOf('week').format('YYYY-MM-DD');
    const end = today.endOf('week').format('YYYY-MM-DD');

    handleDateChange(start, end);
  };

  const zoomMonth = () => {
    const today = moment();

    const start = today.startOf('month').format('YYYY-MM-DD');
    const end = today.endOf('month').format('YYYY-MM-DD');

    handleDateChange(start, end);
  };

  const prevMonth = () => {
    const nextMonth = moment(startTime).add(-1, 'month');

    const start = nextMonth.startOf('month').format('YYYY-MM-DD');
    const end = nextMonth.endOf('month').format('YYYY-MM-DD');

    handleDateChange(start, end);
  };

  const nextWeek = () => {
    const nextMonth = moment(startTime).add(1, 'week');

    const start = nextMonth.startOf('week').format('YYYY-MM-DD');
    const end = nextMonth.endOf('week').format('YYYY-MM-DD');

    handleDateChange(start, end);
  };

  const prevWeek = () => {
    const nextMonth = moment(startTime).add(-1, 'week');

    const start = nextMonth.startOf('week').format('YYYY-MM-DD');
    const end = nextMonth.endOf('week').format('YYYY-MM-DD');

    handleDateChange(start, end);
  };

  const leftButton = () => {
    const timeDelta = endTime - startTime;
    const weekDelta = 1000 * 60 * 60 * 24 * 7;

    if (timeDelta > weekDelta) {
      return <button onClick={prevMonth}>Previous month</button>;
    }
    return <button onClick={prevWeek}>Previous week</button>;
  };

  const middleButton = () => {
    const timeDelta = endTime - startTime;
    const weekDelta = 1000 * 60 * 60 * 24 * 7;

    if (timeDelta > weekDelta) {
      return <button onClick={zoomThisWeek}>This week</button>;
    }
    return <button onClick={zoomMonth}>This month</button>;
  };

  const rightButton = () => {
    const timeDelta = endTime - startTime;
    const weekDelta = 1000 * 60 * 60 * 24 * 7;

    if (timeDelta > weekDelta) {
      return <button onClick={nextMonth}>Next month</button>;
    }
    return <button onClick={nextWeek}>Next week</button>;
  };

  return (
    <div className="timeline--navigation-container">
      {leftButton()}
      {middleButton()}
      {rightButton()}
    </div>
  );
};

TimelineNavigation.propTypes = {
  handleDateChange: PropTypes.func.isRequired,
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired
};

export default TimelineNavigation;
