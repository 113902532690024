/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './NativeSelect.scss';

export default class NativeSelect extends PureComponent {
  render() {
    return (
      <select
        className={`native-select ${this.props.className}`}
        value={this.props.value}
        onChange={this.props.onChange}
        disabled={this.props.disabled}
      >
        {this.props.data.map((d, i) => {
          if (!Array.isArray(d.children)) {
            return (
              <option
                key={`${i}-${d.id}`}
                value={d.id}
                disabled={d.disabled === true}
              >
                {d.text}
              </option>
            );
          }

          return (
            <optgroup key={`${d.text}-${i}`} label={d.text}>
              {d.children.map((dd, ii) => (
                <option
                  key={`${d.text}-${i}-${ii}-${dd.id}`}
                  value={dd.id}
                  disabled={dd.disabled === true}
                >
                  {dd.text}
                </option>
              ))}
            </optgroup>
          );
        })}
      </select>
    );
  }
}

NativeSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      disabled: PropTypes.bool,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          text: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          disabled: PropTypes.bool
        })
      )
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string
};
