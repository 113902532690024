import React, { Component } from 'react';
import ToolTip from '../../../Tooltip';
import './styles.scss';

class SearchBarHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false
    };

    this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow() {
    this.setState({
      showTooltip: !this.state.showTooltip
    });
  }

  render() {
    return (
      <div
        className="search-bar--header"
        onMouseEnter={this.toggleShow}
        onMouseLeave={this.toggleShow}
      >
        Search by name{' '}
        <i className="fa fa-info fa-lg">
          <ToolTip show={this.state.showTooltip}>
            <div className="search-bar--tooltip">
              <p>
                You can search multiple names by separating them with a comma
              </p>
              <p>Search can be saved by bookmarking this site's current url</p>
            </div>
          </ToolTip>
        </i>
      </div>
    );
  }
}

export default SearchBarHeader;
