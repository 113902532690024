import { useSelector } from 'react-redux';

/**
 * Hook to get user default hours amount
 *
 * @returns {number} User default hours
 */
const useUserDefaultHours = () => {
  return useSelector((state) =>
    state.getIn(['hours', 'data', 'defaultWorkHours'])
  );
};

export default useUserDefaultHours;
