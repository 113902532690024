/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import { fromJS } from 'immutable';
import { loop, Effects } from 'redux-loop';
import * as api from './api';

const ACTION_TYPES = {
  UPLOAD_HOLIDAYS: 'UPLOAD_HOLIDAYS',
  UPLOAD_HOURS: 'UPLOAD_HOURS',
  UPLOAD_OVERWORK_REPORT: 'UPLOAD_OVERWORK_REPORT',
  UPLOAD_OVERTIME_DELTAS: 'UPLOAD_OVERTIME_DELTAS'
};

export function uploadHolidays(file) {
  return {
    type: ACTION_TYPES.UPLOAD_HOLIDAYS,
    payload: {
      file
    }
  };
}

export function uploadOverworkReport(file) {
  return {
    type: ACTION_TYPES.UPLOAD_OVERWORK_REPORT,
    payload: {
      file
    }
  };
}

export function uploadHours(file) {
  return {
    type: ACTION_TYPES.UPLOAD_HOURS,
    payload: {
      file
    }
  };
}

export function uploadOvertimeDeltas(file) {
  return {
    type: ACTION_TYPES.UPLOAD_OVERTIME_DELTAS,
    payload: {
      file
    }
  };
}

const initialState = fromJS({
  holidays: {
    uploading: false,
    uploadError: ''
  },
  hours: {
    uploading: false,
    uploadError: ''
  },
  overworkReport: {
    uploading: false,
    uploadError: ''
  },
  overtimeDeltas: {
    uploading: false,
    uploadError: ''
  }
});

export default function uploadReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.UPLOAD_HOLIDAYS: {
      return loop(
        state.setIn(['holidays', 'uploading'], true),
        Effects.promise(api.uploadHolidays, action.payload.file)
      );
    }
    case api.RESULTS.UPLOAD_HOLIDAYS_SUCCESS: {
      return loop(
        state
          .setIn(['holidays', 'uploading'], false)
          .setIn(['holidays', 'uploadError'], ''),
        Effects.promise(api.fetchTeamMembers)
      );
    }
    case api.RESULTS.UPLOAD_HOLIDAYS_ERROR: {
      return state
        .setIn(['holidays', 'uploading'], false)
        .setIn(['holidays', 'uploadError'], fromJS(action.payload));
    }
    case ACTION_TYPES.UPLOAD_HOURS: {
      return loop(
        state.setIn(['hours', 'uploading'], true),
        Effects.promise(api.uploadHours, action.payload.file)
      );
    }
    case ACTION_TYPES.UPLOAD_OVERTIME_DELTAS: {
      return loop(
        state.setIn(['overtimeDeltas', 'uploading'], true),
        Effects.promise(api.uploadOvertimeDeltas, action.payload.file)
      );
    }
    case api.RESULTS.UPLOAD_OVERTIME_DELTAS_SUCCESS: {
      return loop(
        state
          .setIn(['overtimeDeltas', 'uploading'], false)
          .setIn(['overtimeDeltas', 'uploadError'], ''),
        Effects.promise(api.fetchTeamMembers)
      );
    }
    case api.RESULTS.UPLOAD_OVERTIME_DELTAS_ERROR: {
      return state
        .setIn(['overtimeDeltas', 'uploading'], false)
        .setIn(['overtimeDeltas', 'uploadError'], fromJS(action.payload));
    }
    case api.RESULTS.UPLOAD_HOURS_SUCCESS: {
      return loop(
        state
          .setIn(['hours', 'uploading'], false)
          .setIn(['hours', 'uploadError'], ''),
        Effects.promise(api.fetchTeamMembers)
      );
    }
    case api.RESULTS.UPLOAD_HOURS_ERROR: {
      return state
        .setIn(['hours', 'uploading'], false)
        .setIn(['hours', 'uploadError'], fromJS(action.payload));
    }
    case ACTION_TYPES.UPLOAD_OVERWORK_REPORT: {
      return loop(
        state.setIn(['overworkReport', 'uploading'], true),
        Effects.promise(api.uploadOverworkReport, action.payload.file)
      );
    }
    case api.RESULTS.UPLOAD_OVERWORK_REPORT_SUCCESS: {
      return loop(
        state
          .setIn(['overworkReport', 'uploading'], false)
          .setIn(['overworkReport', 'uploadError'], ''),
        Effects.promise(api.fetchTeamMembers)
      );
    }
    case api.RESULTS.UPLOAD_OVERWORK_REPORT_ERROR: {
      return state
        .setIn(['overworkReport', 'uploading'], false)
        .setIn(['overworkReport', 'uploadError'], fromJS(action.payload));
    }
    default: {
      return state;
    }
  }
}
