/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Map } from 'immutable';
import onClickOutside from 'react-onclickoutside';
import './BalanceEditor.scss';

class BalanceEditor extends PureComponent {
  constructor(props) {
    super(props);
    this.setHours = this.setHours.bind(this);
    this.setAnnual = this.setAnnual.bind(this);
    this.setType = this.setType.bind(this);
    this.onDeltaChange = this.onDeltaChange.bind(this);
    this.onCommentChange = this.onCommentChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);

    this.state = {
      delta: '0',
      comment: '',
      type: 0,
      dropdownOpened: false,
      options: [
        { id: 0, text: 'Flex hours' },
        { id: 1, text: 'Holiday' },
        { id: 3, text: 'Long career holiday' },
        { id: 4, text: 'Additional holiday' }
      ]
    };
  }

  handleClickOutside() {
    this.props.hide();
  }

  setHours() {
    this.setType(0);
  }

  setAnnual() {
    this.setType(1);
  }

  setOverwork() {
    this.setType(2);
  }

  setType(type) {
    this.toggleDropdown();
    this.setState({ type }, () =>
      this.onDeltaChange({ target: { value: this.state.delta } })
    );
  }

  onDeltaChange(event) {
    let re = /[-+]?[0-9]*[.,]?[0-9]*/;
    // Allow decimal input for Flex hours only
    if (this.state.type > 0) {
      re = /[-+]?[0-9]*/;
    }
    this.setState({ delta: event.target.value.match(re)[0] });
  }

  onCommentChange(event) {
    this.setState({ comment: event.target.value });
  }

  toggleDropdown() {
    this.setState({
      dropdownOpened: !this.state.dropdownOpened
    });
  }

  onSubmit() {
    this.props.saveDelta({
      change: parseFloat(this.state.delta.replace(',', '.')),
      userId: this.props.user.get('id'),
      comment: this.state.comment,
      type: this.state.type
    });
    this.props.hide();
  }

  render() {
    const firstName = this.props.user.get('firstName');
    const id = this.props.user.get('id');

    const tooltip = (
      <div>
        <div className="ignore-react-onclickoutside hr-larp">
          Use TimeEgg as{' '}
          <a href={`${window.location.origin}/?userId=${id}`}>{firstName}</a>
        </div>
        <div
          className={
            this.props.show
              ? 'balance-editor-tooltip show'
              : 'balance-editor-tooltip'
          }
        >
          <div
            className="balance-editor"
            ref={(node) => {
              this.node = node;
            }}
          >
            <div className="input-group">
              <label className="label">Type</label>

              <div
                className={
                  this.state.dropdownOpened
                    ? 'dropdown open'
                    : 'dropdown closed'
                }
              >
                <div className="selected" onClick={this.toggleDropdown}>
                  {
                    this.state.options.find((o) => o.id === this.state.type)
                      .text
                  }
                  <i className="fa fa-angle-down" />
                </div>
                <div className="dropdown-content">
                  {this.state.options &&
                    this.state.options.map((option) => {
                      return (
                        <div
                          className={
                            this.state.type === option.id
                              ? 'option current'
                              : 'option'
                          }
                          onClick={() => this.setType(option.id)}
                          key={option.id}
                        >
                          {option.text}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

            <div className="input-group">
              <label className="label">Alteration</label>
              <input
                type="text"
                className="text-input"
                value={this.state.delta}
                onChange={this.onDeltaChange}
              />
            </div>

            <div className="input-group">
              <label className="label">Comment</label>
              <input
                type="text"
                className="text-input"
                value={this.state.comment}
                onChange={this.onCommentChange}
              />
            </div>

            <button
              type="submit"
              className="save-button"
              onClick={this.onSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );

    return tooltip;
  }
}

BalanceEditor.propTypes = {
  user: PropTypes.instanceOf(Map).isRequired,
  saveDelta: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired, // Never used?
  clearBalanceChange: PropTypes.func.isRequired, // Never used?
  balanceChange: PropTypes.instanceOf(Map), // Never used?
  hide: PropTypes.func.isRequired,
  show: PropTypes.bool
};

export default onClickOutside(BalanceEditor);
