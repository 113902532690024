/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import { combineReducers } from 'redux-loop';
import { Map } from 'immutable';

import user from './user';
import hours from './hours';
import balanceChange from './balanceChange';
import holidays from './holidays';
import uploader from './uploader';
import teamMembers from './teamMembers';

export default combineReducers(
  {
    user,
    hours,
    balanceChange,
    holidays,
    uploader,
    teamMembers
  },
  Map(),
  (child, key) => child.get(key),
  (child, key, value) => child.set(key, value)
);
