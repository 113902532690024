/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import { connect } from 'react-redux';
import App from './App';
import { fetchUser, updateUser } from '../../reducers/user';
import {
  fetchHours,
  fetchFutureHours,
  fetchPastHours,
  deleteMonthScrollAnchor
} from '../../reducers/hours';
import { scrollToDay } from '../../reducers/day';
import { fetchHolidays } from '../../reducers/holidays';
import {
  uploadHours,
  uploadHolidays,
  uploadOverworkReport,
  uploadOvertimeDeltas
} from '../../reducers/uploader';
import { fetchTeamMembers } from '../../reducers/teamMembers';

export default connect(
  (state) => ({
    user: state.get('user'),
    hours: state.get('hours'),
    teamMembers: state.get('teamMembers'),
    holidays: state.get('holidays'),
    uploader: state.get('uploader'),
    projects: state.getIn(['hours', 'data', 'projects'])
  }),
  (dispatch) => ({
    init() {
      dispatch(fetchUser());
      dispatch(fetchHours());
      dispatch(fetchHolidays());
      dispatch(fetchTeamMembers());
    },
    fetchFutureHours(date) {
      dispatch(fetchFutureHours(date));
    },
    fetchPastHours(date) {
      dispatch(fetchPastHours(date));
    },
    scrolledToAnchor() {
      dispatch(deleteMonthScrollAnchor());
    },
    scrollToDay(date) {
      dispatch(scrollToDay(date));
    },
    updateUser(user) {
      dispatch(updateUser(user));
    },
    uploadHolidays(file) {
      dispatch(uploadHolidays(file));
    },
    uploadOverworkReport(file) {
      dispatch(uploadOverworkReport(file));
    },
    uploadHours(file) {
      dispatch(uploadHours(file));
    },
    uploadOvertimeDeltas(file) {
      dispatch(uploadOvertimeDeltas(file));
    }
  })
)(App);
