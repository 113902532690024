/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Map } from 'immutable';
import BalanceEditor from './BalanceEditor';
import './Employee.scss';

export default class Employee extends PureComponent {
  constructor() {
    super();
    this.toggleBalanceEditor = this.toggleBalanceEditor.bind(this);
    this.showBalanceEditor = this.showBalanceEditor.bind(this);
    this.hideBalanceEditor = this.hideBalanceEditor.bind(this);

    this.state = {
      showBalanceEditor: false,
      visibleBalanceEditor: false
    };
  }

  toggleBalanceEditor() {
    if (this.state.showBalanceEditor) {
      this.hideBalanceEditor();
    } else {
      this.showBalanceEditor();
    }
  }

  showBalanceEditor() {
    this.setState({
      showBalanceEditor: true
    });

    setTimeout(
      () =>
        this.setState({
          visibleBalanceEditor: true
        }),
      50
    );
  }

  hideBalanceEditor() {
    this.setState({
      visibleBalanceEditor: false
    });
    setTimeout(
      () =>
        this.setState({
          showBalanceEditor: false
        }),
      250
    );
  }

  render() {
    const firstName = this.props.user.get('firstName');
    const lastName = this.props.user.get('lastName');
    const crunchHours = this.props.user.get('crunchHours');

    const holidayBalance =
      this.props.user.get('holidaysLeft') !== undefined
        ? this.props.user.get('holidaysLeft')
        : 0;
    const additionalHolidayBalance =
      this.props.user.get('additionalHolidaysLeft') !== undefined
        ? this.props.user.get('additionalHolidaysLeft')
        : 0;
    const longCareerHolidayBalance =
      this.props.user.get('longCareerHolidaysLeft') !== undefined
        ? this.props.user.get('longCareerHolidaysLeft')
        : 0;

    const totalHolidaysLeft =
      holidayBalance + additionalHolidayBalance + longCareerHolidayBalance;

    const profilePicture = this.props.user.get('profilePicture');
    const disabled = this.props.user.get('disabled');

    return (
      <div className={`${disabled ? 'employee disabled' : 'employee'}`}>
        <div
          className="profile-picture ignore-react-onclickoutside"
          onClick={this.toggleBalanceEditor}
          style={{
            backgroundImage: `url('${profilePicture}')`,
            visibility: profilePicture !== '' ? 'visible' : 'hidden'
          }}
        />
        <div className="info">
          <div className="upper-row">
            <span className="name">{firstName}</span>
            <span className="hours">
              {crunchHours} <i className="fa fa-hourglass-end left-pane-icon" />
            </span>
          </div>
          <div className="lower-row">
            <span className="name">{lastName}</span>
            <span className="hours">
              {totalHolidaysLeft} <i className="fa fa-plane left-pane-icon" />
            </span>
          </div>
        </div>

        {this.props.hr && this.state.showBalanceEditor ? (
          <div>
            <BalanceEditor
              show={
                this.props.hr &&
                this.state.showBalanceEditor &&
                this.state.visibleBalanceEditor
              }
              user={this.props.user}
              clearBalanceChange={this.props.clearBalanceChange}
              balanceChange={this.props.balanceChange}
              fetchUser={this.props.fetchUser}
              saveDelta={this.props.saveDelta}
              hide={this.hideBalanceEditor}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

Employee.propTypes = {
  user: PropTypes.instanceOf(Map),
  saveDelta: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  clearBalanceChange: PropTypes.func.isRequired,
  balanceChange: PropTypes.instanceOf(Map),
  hr: PropTypes.bool
};
