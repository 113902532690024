/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import { fromJS, List } from 'immutable';
import { loop, Effects } from 'redux-loop';
import moment from 'moment';

import * as apiReducer from './api';
import * as dayReducer from './day';

const ACTION_TYPES = {
  FETCH_HOURS: 'FETCH_HOURS',
  FETCH_FUTURE_HOURS: 'FETCH_FUTURE_HOURS',
  FETCH_PAST_HOURS: 'FETCH_PAST_HOURS',
  UPDATE_MISSING_ENTRIES: 'UPDATE_MISSING_ENTRIES',
  DELETE_MONTH_SCROLL_ANCHOR: 'DELETE_MONTH_SCROLL_ANCHOR'
};

/**
 * Fetch this months and previous months hours
 * @returns {{type: string, payload: {startDate: string, endDate: string}}}
 */
export function fetchHours() {
  const format = 'YYYY-MM-DD';
  const endDate = moment(Date.now()).endOf('month');
  endDate.hours(0);
  endDate.minutes(0);
  endDate.seconds(0);
  endDate.milliseconds(0);
  let startDate = moment(endDate).startOf('month');
  const duration = moment.duration(moment().diff(startDate)).days();
  // If duration is less than 14 days, fetch this and the previous month
  if (duration < 13) {
    startDate = moment(endDate);
    startDate.subtract(1, 'months').startOf('month');
  }

  endDate.add(1, 'months'); // always get next month too

  return {
    type: ACTION_TYPES.FETCH_HOURS,
    payload: {
      startDate: moment(startDate).format(format),
      endDate: moment(endDate).format(format)
    }
  };
}

export function fetchFutureHours(date) {
  if (date instanceof Date) {
    return {
      type: ACTION_TYPES.FETCH_FUTURE_HOURS,
      payload: { date }
    };
  }
  return {
    type: ACTION_TYPES.FETCH_FUTURE_HOURS
  };
}

export function fetchPastHours(date) {
  if (date instanceof Date) {
    return {
      type: ACTION_TYPES.FETCH_PAST_HOURS,
      payload: { date }
    };
  }
  return {
    type: ACTION_TYPES.FETCH_PAST_HOURS
  };
}

export function checkMissingEntries() {
  return {
    type: ACTION_TYPES.UPDATE_MISSING_ENTRIES
  };
}

export function deleteMonthScrollAnchor() {
  return {
    type: ACTION_TYPES.DELETE_MONTH_SCROLL_ANCHOR
  };
}

const initialState = fromJS({
  fetching: false,
  fetchError: ''
});

function parseMonth(date) {
  if (!/\d\d\d\d-\d\d-\d\d/.test(date)) {
    return false;
  }

  const month = /\d\d\d\d-\d\d/.exec(date);

  if (month.length === 0) {
    return false;
  }

  return month[0];
}

function handleDayAction(state, action) {
  const month = parseMonth(action.payload.date);
  if (!month) {
    return state;
  }
  return state.updateIn(
    ['data', 'months', month, 'days', action.payload.date],
    (day) => dayReducer.default(day, action, state.get('data'))
  );
}

function injectEntriesWithProjectData2(day, projects) {
  return day.update('entries', (entries) => {
    if (!List.isList(entries)) {
      return List();
    }
    return entries.map((entry) => {
      const project = projects
        .filter((project) => project.get('id') === entry.get('projectId'))
        .first();

      if (!project) {
        return entry;
      }

      const projectName = project.get('name');

      const task = project
        .get('tasks')
        .filter((task) => task.get('id') === entry.get('taskId'))
        .first();

      if (!task) {
        return entry.set('projectName', projectName);
      }

      const taskName = task.get('name');
      const latestDescription = task.getIn(['latestEntry', 'description']);

      return entry
        .set('projectName', projectName)
        .set('taskName', taskName)
        .set('latestDescription', latestDescription);
    });
  });
}

// TODO: deprecate?
function injectEntriesWithProjectData(day, state) {
  return injectEntriesWithProjectData2(day, state.getIn(['data', 'projects']));
}

function isHoliday(day) {
  const type = day.get('type');
  return typeof type === 'string' ? type : false;
}

function isWeekend(day) {
  return day.get('type') === true;
}

function isTodayOrPast(date) {
  const now = moment(Date.now()).format('YYYY-MM-DD');
  return date <= now;
}

const fetchHoursFields = {
  fetching: {
    [ACTION_TYPES.FETCH_HOURS]: 'fetching',
    [ACTION_TYPES.FETCH_FUTURE_HOURS]: 'fetchingFuture',
    [ACTION_TYPES.FETCH_PAST_HOURS]: 'fetchingPast',
    [apiReducer.RESULTS.FETCH_HOURS_SUCCESS]: 'fetching',
    [apiReducer.RESULTS.FETCH_FUTURE_HOURS_SUCCESS]: 'fetchingFuture',
    [apiReducer.RESULTS.FETCH_PAST_HOURS_SUCCESS]: 'fetchingPast',
    [apiReducer.RESULTS.FETCH_HOURS_ERROR]: 'fetching',
    [apiReducer.RESULTS.FETCH_FUTURE_HOURS_ERROR]: 'fetchingFuture',
    [apiReducer.RESULTS.FETCH_PAST_HOURS_ERROR]: 'fetchingPast'
  },
  error: {
    [apiReducer.RESULTS.FETCH_HOURS_SUCCESS]: 'fetchError',
    [apiReducer.RESULTS.FETCH_FUTURE_HOURS_SUCCESS]: 'fetchFutureError',
    [apiReducer.RESULTS.FETCH_PAST_HOURS_SUCCESS]: 'fetchPastError',
    [apiReducer.RESULTS.FETCH_HOURS_ERROR]: 'fetchError',
    [apiReducer.RESULTS.FETCH_FUTURE_HOURS_ERROR]: 'fetchFutureError',
    [apiReducer.RESULTS.FETCH_PAST_HOURS_ERROR]: 'fetchPastError'
  }
};

function updateHoursData(state, data) {
  const resultMonths = data.get('months');
  const resultProjects = data.get('projects');

  // update data, but don't update months
  return state.update('data', (oldData) => {
    // If there is no old data, return new
    if (!oldData) {
      // TODO: make injections in one place
      const data2 = data.set('projects', resultProjects);
      return data2.update('months', (months) =>
        months.map((month) =>
          month.update('days', (days) =>
            days.map((day) =>
              injectEntriesWithProjectData2(day, resultProjects)
            )
          )
        )
      );
    }

    // Default working hours
    const data1 = oldData.set('defaultWorkHours', data.get('defaultWorkHours'));
    // Projects
    const data2 = data1.update('projects', (projects) => {
      // if there is no projects us one in data
      if (!projects || projects.length === 0) return resultProjects;

      // TODO: merge projects data
      // console.log(projects, resultProjects);
      return projects;
    });

    // Months
    const data3 = data2.update('months', (months) => {
      // if there is no months, use the one in data
      if (!months) return resultMonths;

      // otherwise merge
      function monthMerger(oldMonth, newMonth) {
        return oldMonth
          .set('hours', newMonth.get('hours', 0))
          .update('days', (days) => days.merge(newMonth.get('days'))); // newMonth preferred
      }

      return months.mergeWith(
        monthMerger,
        resultMonths.map((month) =>
          month.update('days', (days) =>
            days.map((day) =>
              injectEntriesWithProjectData2(day, data2.get('projects'))
            )
          )
        )
      );
    });
    // Return new data
    return data3;
  });
}

export default function hoursReducer(state = initialState, action = {}) {
  switch (action.type) {
    /* OWN ACTIONS */
    case ACTION_TYPES.FETCH_HOURS: {
      return loop(
        state.set(fetchHoursFields.fetching[action.type], true),
        Effects.promise(
          apiReducer.fetchHours,
          action.payload.startDate,
          action.payload.endDate
        )
      );
    }
    case ACTION_TYPES.FETCH_FUTURE_HOURS: {
      const month = state
        .getIn(['data', 'months'])
        .keySeq()
        .sort((a, b) => {
          // descending
          if (a < b) {
            return 1;
          }
          return -1;
        })
        .first();

      const date = state
        .getIn(['data', 'months', month, 'days'])
        .keySeq()
        .sort((a, b) => {
          // descending
          if (a < b) {
            return 1;
          }
          return -1;
        })
        .first();

      const startDate = moment(date, 'YYYY-MM-DD')
        .add(1, 'days')
        .format('YYYY-MM-DD');

      const endDate = action.payload
        ? moment(action.payload.date)
            .endOf('month')
            .add(1, 'months')
            .format('YYYY-MM-DD')
        : moment(month, 'YYYY-MM')
            .add(2, 'months')
            .subtract(1, 'days')
            .format('YYYY-MM-DD');

      const days = state.getIn(['data', 'months']).reduce((l, m) => {
        if (m.has('days')) {
          return l.concat(Object.keys(m.get('days').toObject()));
        }
        return l;
      }, []);

      const alreadyFetched = action.payload
        ? days.findIndex(
            (day) =>
              moment(day).format('YYYY-MM-DD') ===
              moment(action.payload.date).endOf('month').format('YYYY-MM-DD')
          ) !== -1
        : state.hasIn([
            'data',
            'months',
            moment(month, 'YYYY-MM')
              .add(2, 'months')
              .subtract(1, 'days')
              .format('YYYY-MM')
          ]);

      if (alreadyFetched) return state;

      return loop(
        state.set(fetchHoursFields.fetching[action.type], true),
        Effects.promise(apiReducer.fetchFutureHours, startDate, endDate)
      );
    }
    case ACTION_TYPES.FETCH_PAST_HOURS: {
      const month = state
        .getIn(['data', 'months'])
        .keySeq()
        .sort((a, b) => {
          if (a < b) {
            return 1;
          }
          return -1;
        })
        .last();

      const endDate = moment(month, 'YYYY-MM')
        .subtract(1, 'days')
        .format('YYYY-MM-DD');

      const startDate = action.payload
        ? moment(action.payload.date).startOf('month').format('YYYY-MM-DD')
        : moment(month, 'YYYY-MM').subtract(1, 'months').format('YYYY-MM-DD');

      const alreadyFetched = action.payload
        ? state.hasIn([
            'data',
            'months',
            moment(action.payload.date).startOf('month').format('YYYY-MM')
          ])
        : state.hasIn([
            'data',
            'months',
            moment(month, 'YYYY-MM').subtract(1, 'months').format('YYYY-MM')
          ]);

      if (alreadyFetched) return state;

      return loop(
        state.set(fetchHoursFields.fetching[action.type], true),
        Effects.promise(apiReducer.fetchPastHours, startDate, endDate)
      );
    }
    case ACTION_TYPES.UPDATE_MISSING_ENTRIES: {
      return state.updateIn(['data', 'months'], (months) => {
        return months.map((month) => {
          month = month.update('days', (days) =>
            days.map((day, date) => {
              if (
                day.get('entries').size === 0 &&
                isTodayOrPast(date) &&
                !isWeekend(day) &&
                !isHoliday(day) &&
                !day.get('closed')
              ) {
                // Render plus button only if it's encouraged to mark your hours,
                // i.e. not in the future, not on weekends or holidays
                return day.set('missingEntries', true);
              }
              return day.delete('missingEntries');
            })
          );

          const daysMissingEntries = month
            .get('days')
            .filter((day) => day.get('missingEntries'))
            .keySeq()
            .filter((day) => day !== moment(Date.now()).format('YYYY-MM-DD'))
            .sort((a, b) => {
              // sort to descending order
              if (a < b) {
                return 1;
              }
              return -1;
            });

          if (daysMissingEntries.size > 0) {
            return month.set('missingEntries', daysMissingEntries.first());
          }
          return month.delete('missingEntries');
        });
      });
    }
    case ACTION_TYPES.DELETE_MONTH_SCROLL_ANCHOR: {
      return state.delete('scrollAnchor').delete('scrollAnchorAlignTop');
    }

    /* API RESULTS FOR FETCHING HOURS */
    case apiReducer.RESULTS.FETCH_HOURS_SUCCESS: {
      const data = fromJS(action.payload);
      /*       const scrollAnchor = data.get('months')
        .keySeq()
        .sort((a, b) => {
          if (a < b) {
            return 1;
          }
          return -1;
        })
        .first(); */
      const newState = state
        .set(fetchHoursFields.fetching[action.type], false)
        .set(fetchHoursFields.error[action.type], '');

      return loop(
        updateHoursData(newState, data),
        Effects.constant(checkMissingEntries())
      );
    }

    case apiReducer.RESULTS.FETCH_FUTURE_HOURS_SUCCESS:
    case apiReducer.RESULTS.FETCH_PAST_HOURS_SUCCESS: {
      const data = fromJS(action.payload);
      const resultMonths = data.get('months');
      const resultMonthKeys = resultMonths.keySeq().sort((a, b) => {
        if (a < b) {
          return 1;
        }
        return -1;
      });
      const scrollAnchor = resultMonthKeys.last();
      const scrollAnchorAlignTop = true;

      const newState = state
        .set(fetchHoursFields.fetching[action.type], false)
        .set(fetchHoursFields.error[action.type], '')
        .set('scrollAnchor', scrollAnchor)
        .set('scrollAnchorAlignTop', scrollAnchorAlignTop);

      return loop(
        updateHoursData(newState, data),
        Effects.constant(checkMissingEntries())
      );
    }

    case apiReducer.RESULTS.FETCH_HOURS_ERROR:
    case apiReducer.RESULTS.FETCH_FUTURE_HOURS_ERROR:
    case apiReducer.RESULTS.FETCH_PAST_HOURS_ERROR: {
      return state
        .set(fetchHoursFields.fetching[action.type], false)
        .set(fetchHoursFields.error[action.type], fromJS(action.payload));
    }

    /* DAY EDIT ACTIONS */
    case dayReducer.ACTION_TYPES.EDIT_DAY: {
      const newState = handleDayAction(state, action);

      const month = parseMonth(action.payload.date);
      if (
        newState.getIn([
          'data',
          'months',
          month,
          'days',
          action.payload.date,
          'edit',
          'entries'
        ]).size === 0
      ) {
        return loop(
          newState,
          Effects.batch([
            Effects.constant(dayReducer.addEntry(action.payload.date)),
            Effects.constant(dayReducer.validateDayEdit(action.payload.date))
            // Effects.constant(dayReducer.updateHoursRemaining())
          ])
        );
      }

      return loop(
        newState,
        Effects.batch([
          Effects.constant(dayReducer.validateDayEdit(action.payload.date))
          // Effects.constant(dayReducer.updateHoursRemaining())
        ])
      );
    }

    case dayReducer.ACTION_TYPES.CANCEL_EDIT_DAY:
    case dayReducer.ACTION_TYPES.UPDATE_DAY_EDIT_HOURS:
    case dayReducer.ACTION_TYPES.DELETE_DAY_EDIT_SCROLL_ANCHOR:
    case dayReducer.ACTION_TYPES.VALIDATE_DAY_EDIT:
    case dayReducer.ACTION_TYPES.SCROLL_TO_DAY:
    case dayReducer.ACTION_TYPES.SCROLLED_TO_DAY: {
      return handleDayAction(state, action);
    }

    case dayReducer.ACTION_TYPES.ADD_ENTRY:
    case dayReducer.ACTION_TYPES.EDIT_ENTRY:
    case dayReducer.ACTION_TYPES.DELETE_ENTRY: {
      return loop(
        handleDayAction(state, action),
        Effects.batch([
          Effects.constant(dayReducer.updateDayEditHours(action.payload.date)),
          Effects.constant(dayReducer.validateDayEdit(action.payload.date))
          // Effects.constant(dayReducer.updateHoursRemaining())
        ])
      );
    }

    case dayReducer.ACTION_TYPES.UPDATE_HOURS_REMAINING: {
      return state.updateIn(['data', 'months'], (months) =>
        months.map((month) =>
          month.update('days', (days) =>
            days.map((day) =>
              dayReducer.default(day, action, state.get('data'))
            )
          )
        )
      );
    }

    /* DAY ACTION AND API RESULTS FOR SAVING DAY */
    case dayReducer.ACTION_TYPES.SAVE_DAY: {
      const newState = handleDayAction(state, action);
      const month = parseMonth(action.payload.date);
      return loop(
        newState,
        Effects.promise(
          apiReducer.saveEntries,
          action.payload.date,
          newState
            .getIn([
              'data',
              'months',
              month,
              'days',
              action.payload.date,
              'edit',
              'entries'
            ])
            .toJS()
        )
      );
    }

    case apiReducer.RESULTS.SAVE_ENTRIES_SUCCESS: {
      const { date } = action.payload;
      const month = date.match(/^(\d{4}-\d{2})/)[1];
      const results = fromJS(action.payload.results);

      // get user from the last result only
      const user = results.last().get('user');
      // but accumulate hours data from all
      const state1 = results.reduce(
        (s, d) => updateHoursData(s, d.get('hours')),
        state
      );
      // remove edit
      // TODO: this shouldn't be necessary.
      const state2 = state1.removeIn([
        'data',
        'months',
        month,
        'days',
        date,
        'edit'
      ]);

      // new state is state2
      const newState = state2;

      return loop(
        newState,
        Effects.batch([
          Effects.constant({
            type: apiReducer.RESULTS.FETCH_USER_SUCCESS,
            payload: user.toJS()
          }),
          Effects.constant(checkMissingEntries())
          // Effects.constant(dayReducer.updateHoursRemaining())
        ])
      );
    }

    case apiReducer.RESULTS.SAVE_ENTRIES_ERROR: {
      const results = fromJS(action.payload.results);
      const { date } = action.payload;
      const monthDate = parseMonth(date);
      if (results.every((result) => result.get('error'))) {
        return state.updateIn(
          ['data', 'months', monthDate, 'days', date, 'edit'],
          (edit) =>
            edit
              .set('error', 'Could not save entries')
              .set('saving', false)
              .set('scrollToTop', true)
              .update('entries', (entries) =>
                entries.map((entry, i) =>
                  entry.set('error', results.getIn([i, 'error']))
                )
              )
        );
      }
      const deletedFlag = true;
      const resultEntries = results.map((result) => {
        if (result.get('error')) {
          return false;
        }
        return result.getIn(
          ['hours', 'months', monthDate, 'days', date, 'entry'],
          deletedFlag
        );
      });

      const lastSuccessful = results
        .filter((result) => !result.get('error'))
        .last();
      const newState = state
        .update('data', (data) =>
          data.merge(lastSuccessful.get('hours').delete('months'))
        )
        .updateIn(['data', 'months', monthDate], (month) =>
          month.merge(
            lastSuccessful.getIn(['hours', 'months', monthDate]).delete('days')
          )
        )
        .updateIn(['data', 'months', monthDate, 'days', date], (day) => {
          const dayEdit = day
            .merge(
              lastSuccessful
                .getIn(['hours', 'months', monthDate, 'days', date])
                .delete('entry')
            )
            .setIn(['edit', 'error'], 'Could not save entries')
            .setIn(['edit', 'saving'], false)
            .setIn(['edit', 'scrollToTop'], true)
            .updateIn(['edit', 'entries'], (entries) =>
              entries
                .map((entry, i) => {
                  const resultEntry = resultEntries.get(i);
                  if (!resultEntry) {
                    return entry.set('error', results.getIn([i, 'error']));
                  }
                  return resultEntry;
                })
                .filter((entry) => entry !== deletedFlag)
            );

          return dayEdit.set(
            'entries',
            dayEdit
              .getIn(['edit', 'entries'])
              .map((entry) => {
                if (entry.get('error')) {
                  // new entries with error are filtered out below
                  return dayEdit
                    .get('entries')
                    .filter((e) => e.get('id') === entry.get('id'))
                    .first();
                }
                return entry.delete('new').delete('deleted');
              })
              .filter((entry) => entry)
          );
        });

      return loop(
        newState.updateIn(['data', 'months', monthDate, 'days'], (days) =>
          days.map((day) => {
            let d = day;
            if (d.get('edit')) {
              d = d.update('edit', (edit) =>
                injectEntriesWithProjectData(edit, newState)
              );
            }
            return injectEntriesWithProjectData(d, newState);
          })
        ),
        Effects.batch([
          Effects.constant({
            type: apiReducer.RESULTS.FETCH_USER_SUCCESS,
            payload: lastSuccessful.get('user').toJS()
          }),
          Effects.constant(checkMissingEntries())
          // Effects.constant(dayReducer.updateHoursRemaining())
        ])
      );
    }
    default: {
      return state;
    }
  }
}
