/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import IdleTimer from 'react-idle-timer';

import Sidebar from 'react-sidebar';
import Modal from 'react-modal';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import UserWarning from '../UserWarning';
import TestEnvWarning from '../TestEnvWarning';
import Header from '../Header';
import MonthList from '../MonthList';
import MobilePane from '../LeftPane/MobilePaneContainer';

import Precences from '../Absences';
import HoursReport from '../HoursReport';

Modal.setAppElement('#app');

export default class App extends PureComponent {
  constructor(props) {
    super(props);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.state = {
      sidebarOpen: false
    };
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  componentDidMount() {
    this.props.init();

    if (process.env.NODE_ENV === 'production') {
      window.addEventListener('beforeunload', (e) => {
        if (
          this.props.hours
            .getIn(['data', 'months'], List())
            .some((month) =>
              month.get('days', List()).some((day) => day.get('edit'))
            )
        ) {
          const message =
            'You have unsaved changes, are you sure you want to leave?';
          (e || window.event).returnValue = message;
          return message;
        }
      });
    }
  }

  render() {
    const app = (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={1000 * 15 * 60}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={2000}
        />
        <div className="app">
          <Header
            user={this.props.user}
            updateUser={this.props.updateUser}
            openSideMenu={this.onSetSidebarOpen}
            projects={this.props.projects}
          />

          <Router>
            <Switch>
              <Route exact path="/hours-report" component={HoursReport} />
              <Route exact path="/absences" component={Precences} />
              <Route
                exact
                path="/"
                // eslint-disable-next-line react/jsx-no-bind
                render={(props) => (
                  <MonthList
                    hours={this.props.hours}
                    fetchFutureHours={this.props.fetchFutureHours}
                    fetchPastHours={this.props.fetchPastHours}
                    scrolledToAnchor={this.props.scrolledToAnchor}
                    scrollToDay={this.props.scrollToDay}
                    user={this.props.user}
                    teamMembers={this.props.teamMembers}
                    uploadHolidays={this.props.uploadHolidays}
                    uploadOverworkReport={this.props.uploadOverworkReport}
                    uploadHours={this.props.uploadHours}
                    uploadOvertimeDeltas={this.props.uploadOvertimeDeltas}
                    uploader={this.props.uploader}
                    {...props}
                  />
                )}
              />
              <Route path="/*" render={() => <Redirect to="/" />} />
            </Switch>
          </Router>

          {window.location.hostname.indexOf('timeegg01') !== -1 ? (
            <TestEnvWarning />
          ) : null}
          {window.location.search.indexOf('userId') !== -1 ? (
            <UserWarning user={this.props.user} />
          ) : null}
        </div>
      </>
    );

    // showing side menu is based on viewport width, not on device
    const w = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );

    if (w < 950) {
      const isSupervisor = this.props.user.getIn(['data', 'isSupervisor']);

      if (isSupervisor) {
        return (
          <Sidebar
            sidebar={<MobilePane />}
            open={this.state.sidebarOpen}
            onSetOpen={this.onSetSidebarOpen}
            styles={{ sidebar: { zIndex: 4 }, overlay: { zIndex: 3 } }}
          >
            {app}
          </Sidebar>
        );
      }
    }

    return app;
  }

  handleOnAction() {}

  handleOnActive() {
    this.props.init();
  }

  handleOnIdle() {}
}

App.propTypes = {
  init: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map),
  teamMembers: PropTypes.instanceOf(Map),
  hours: PropTypes.instanceOf(Map),
  fetchFutureHours: PropTypes.func.isRequired,
  fetchPastHours: PropTypes.func.isRequired,
  scrolledToAnchor: PropTypes.func.isRequired,
  scrollToDay: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  uploadHolidays: PropTypes.func.isRequired,
  uploadHours: PropTypes.func.isRequired,
  uploadOvertimeDeltas: PropTypes.func.isRequired,
  uploadOverworkReport: PropTypes.func.isRequired,
  uploader: PropTypes.instanceOf(Map).isRequired,
  projects: PropTypes.instanceOf(List)
};
