/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import { fromJS, Map, List } from 'immutable';
import moment from 'moment';
import { BUSINESS_TRIP_TASK_ID } from '../utils/config';

export const ACTION_TYPES = {
  EDIT_DAY: 'EDIT_DAY',
  CANCEL_EDIT_DAY: 'CANCEL_EDIT_DAY',
  SAVE_DAY: 'SAVE_DAY',
  ADD_ENTRY: 'ADD_ENTRY',
  EDIT_ENTRY: 'EDIT_ENTRY',
  DELETE_ENTRY: 'DELETE_ENTRY',
  UPDATE_DAY_EDIT_HOURS: 'UPDATE_DAY_EDIT_HOURS',
  VALIDATE_DAY_EDIT: 'VALIDATE_DAY_EDIT',
  UPDATE_HOURS_REMAINING: 'UPDATE_HOURS_REMAINING',
  SCROLL_TO_DAY: 'SCROLL_TO_DAY',
  SCROLLED_TO_DAY: 'SCROLLED_TO_DAY',
  DELETE_DAY_EDIT_SCROLL_ANCHOR: 'DELETE_DAY_EDIT_SCROLL_ANCHOR'
};

export function editDay(date) {
  return {
    type: ACTION_TYPES.EDIT_DAY,
    payload: {
      date
    }
  };
}

export function cancelEditDay(date) {
  return {
    type: ACTION_TYPES.CANCEL_EDIT_DAY,
    payload: {
      date
    }
  };
}

export function saveDay(date) {
  return {
    type: ACTION_TYPES.SAVE_DAY,
    payload: {
      date
    }
  };
}

export function addEntry(date) {
  return {
    type: ACTION_TYPES.ADD_ENTRY,
    payload: {
      date
    }
  };
}

export function editEntry(date, id, entry) {
  return {
    type: ACTION_TYPES.EDIT_ENTRY,
    payload: {
      date,
      id,
      entry
    }
  };
}

export function deleteEntry(date, id) {
  return {
    type: ACTION_TYPES.DELETE_ENTRY,
    payload: {
      date,
      id
    }
  };
}

export function validateDayEdit(date) {
  return {
    type: ACTION_TYPES.VALIDATE_DAY_EDIT,
    payload: {
      date
    }
  };
}

export function updateDayEditHours(date) {
  return {
    type: ACTION_TYPES.UPDATE_DAY_EDIT_HOURS,
    payload: {
      date
    }
  };
}

export function scrollToDay(date) {
  return {
    type: ACTION_TYPES.SCROLL_TO_DAY,
    payload: {
      date
    }
  };
}

export function scrolledToDay(date) {
  return {
    type: ACTION_TYPES.SCROLLED_TO_DAY,
    payload: {
      date
    }
  };
}

export function deleteDayEditScrollAnchor(date) {
  return {
    type: ACTION_TYPES.DELETE_DAY_EDIT_SCROLL_ANCHOR,
    payload: {
      date
    }
  };
}

export function updateHoursRemaining() {
  return {
    type: ACTION_TYPES.UPDATE_HOURS_REMAINING
  };
}

function updateEntryTask(entry, activeProjects) {
  const task = activeProjects
    .filter((project) => project.get('id') === entry.get('projectId'))
    .first()
    .get('tasks')
    .first();
  const taskId = task.get('id');
  const latestDescription = task.getIn(['latestEntry', 'description']);

  return entry.merge(
    Map({
      taskId,
      latestDescription
    })
  );
}

function updateEntryLatestMarking(entry, data) {
  const task = data
    .get('projects')
    .filter((project) => project.get('id') === entry.get('projectId'))
    .first()
    .get('tasks')
    .filter((t) => t.get('id') === entry.get('taskId'))
    .first();

  const latestDescription = task.getIn(['latestEntry', 'description']);

  return entry.merge(
    Map({
      latestDescription
    })
  );
}

// TODO: replace with uuid or something
let id = 0;

export default function dayReducer(state, action, data) {
  switch (action.type) {
    case ACTION_TYPES.EDIT_DAY: {
      return state.set('edit', state).setIn(
        ['edit', 'activeProjects'],
        data
          .get('projects')
          .map((project) =>
            project.update('tasks', (tasks) =>
              tasks.filter((task) => {
                if (
                  state.get('type').length > 0 &&
                  task.get('absence') &&
                  task.get('id') !== BUSINESS_TRIP_TASK_ID
                ) {
                  return false;
                }

                if (
                  state
                    .get('entries')
                    .some((entry) => entry.get('taskId') === task.get('id'))
                ) {
                  return true;
                }

                if (!task.getIn(['latestEntry', 'date'])) {
                  return true;
                }

                const now = moment(Date.now());
                now.date(1);
                /* Hide projects and task that are closed and where the latest 
                entry was over two months ago */
                return (
                  task.getIn(['latestEntry', 'date']) >=
                  now.subtract(2, 'months').format('YYYY-MM-DD')
                );
              })
            )
          )
          .filter((project) => project.get('tasks').size > 0)
      );
    }
    case ACTION_TYPES.CANCEL_EDIT_DAY: {
      return state.delete('edit');
    }
    case ACTION_TYPES.SAVE_DAY: {
      return state
        .setIn(['edit', 'saving'], true)
        .updateIn(['edit', 'entries'], (entries) =>
          entries.map((entry) => {
            if (entry.get('description', '') === '') {
              return entry.set('description', entry.get('latestDescription'));
            }
            return entry;
          })
        );
    }
    case ACTION_TYPES.ADD_ENTRY: {
      const activeProjects = state
        .getIn(['edit', 'activeProjects'])
        .filter((project) =>
          project.get('tasks').some((task) => !task.get('closed'))
        );

      // Select next active project, but avoid absence project(s)
      let project = activeProjects
        .filter((p) =>
          state
            .getIn(['edit', 'entries'])
            .every(
              (entry) =>
                entry.get('projectId') !== p.get('id') && !p.get('absence')
            )
        )
        .first();

      if (!project) {
        project = activeProjects.first();
      }

      if (!project) {
        return state;
      }
      const task = project.get('tasks').first();

      const totalHours = state.getIn(['edit', 'hours']);

      let hours;
      if (!isNaN(task.getIn(['latestEntry', 'hours']))) {
        hours = task.getIn(['latestEntry', 'hours']);
      } else {
        // If absence project, force hours to defaultWorkHours
        if (project.get('absence')) {
          hours = data.get('defaultWorkHours');
        } else {
          hours = Math.max(data.get('defaultWorkHours') - totalHours, 0.5);
        }
      }
      const lunchAddition = hours >= 6 ? 0.5 : 0;

      const entry = {
        id: `new-${++id}`,
        new: true,
        hours,
        projectId: project.get('id'),
        taskId: task.get('id'),
        description: '',
        latestDescription: task.getIn(['latestEntry', 'description']),
        startTime: moment(action.payload.date).hour(8).toDate(),
        endTime: moment(action.payload.date)
          .hour(8)
          .add(hours + lunchAddition, 'hours')
          .toDate()
      };

      return state.updateIn(['edit', 'entries'], (entries) =>
        entries.push(fromJS(entry))
      );
    }
    case ACTION_TYPES.EDIT_ENTRY: {
      return state.updateIn(['edit', 'entries'], (entries) =>
        entries.map((entry) => {
          if (entry.get('id') !== action.payload.id) {
            return entry;
          }

          let newEntry = entry.merge(Map(action.payload.entry));
          const project = state
            .getIn(['edit', 'activeProjects'])
            .filter((p) => p.get('id') === newEntry.get('projectId'))
            .first();

          // If absence project, force hours to defaultWorkHours
          if (project.get('absence')) {
            // const hours = data.get('defaultWorkHours');
            const hours = newEntry.get('hours');
            newEntry = newEntry.merge(Map({ hours }));

            newEntry = newEntry.update('startTime', () =>
              moment(action.payload.date).hour(8).toDate()
            );

            newEntry = newEntry.update('endTime', () => {
              if (hours >= 6) {
                return moment(newEntry.get('startTime'))
                  .add(hours + 0.5, 'hours')
                  .toDate();
              }
              return moment(newEntry.get('startTime'))
                .add(hours, 'hours')
                .toDate();
            });
          }

          if (action.payload.entry.projectId !== undefined) {
            const activeProjects = state.getIn(['edit', 'activeProjects']);
            return updateEntryTask(newEntry, activeProjects);
          }

          if (action.payload.entry.taskId !== undefined) {
            return updateEntryLatestMarking(newEntry, data);
          }

          // sync start/end times and hours
          if (entry.get('hours') === newEntry.get('hours')) {
            const diff = moment.duration(
              moment(newEntry.get('endTime')) -
                moment(newEntry.get('startTime'))
            );
            newEntry = newEntry.update('hours', () => {
              if (diff.hours() >= 6) {
                // subtract 30mins if time is over 6 hours
                return diff.hours() + diff.minutes() / 60 - 0.5;
              }
              return diff.hours() + diff.minutes() / 60;
            });
          } else {
            // move start time if hours can't fit in a day starting at 8
            const startTime = 8 - Math.max(newEntry.get('hours') - 15, 0);
            newEntry = newEntry.update('startTime', () =>
              moment(action.payload.date).hour(startTime).toDate()
            );

            newEntry = newEntry.update('endTime', () => {
              let hours = newEntry.get('hours');

              // add 30mins if time is over 6 hours
              if (hours >= 6) hours += 0.5;

              return moment(newEntry.get('startTime'))
                .add(hours, 'hours')
                .toDate();
            });
          }

          return newEntry;
        })
      );
    }
    case ACTION_TYPES.DELETE_ENTRY: {
      return state.updateIn(['edit', 'entries'], (entries) =>
        entries
          .map((entry) => {
            if (entry.get('id') !== action.payload.id) {
              return entry;
            }
            return entry.set('deleted', true);
          })
          // Remove new entries that were deleted
          .filter((entry) => !(entry.get('new') && entry.get('deleted')))
      );
    }
    case ACTION_TYPES.UPDATE_DAY_EDIT_HOURS: {
      const totalHours = state
        .getIn(['edit', 'entries'])
        .filter((entry) => !entry.get('deleted'))
        .map((entry) => entry.get('hours'))
        .reduce((prev, curr) => prev + curr);

      return state.setIn(['edit', 'hours'], totalHours || 0);
    }
    case ACTION_TYPES.VALIDATE_DAY_EDIT: {
      let validateError;
      let saveDisabled = true;
      if (
        state.get('entries').size === 0 &&
        state.getIn(['edit', 'entries']).size === 0
      ) {
        if (
          state.get('entries').every((entry) => entry.get('closed')) &&
          state
            .getIn(['edit', 'entries'])
            .every((entry) => entry.get('closed')) &&
          !state.getIn(['user', 'isHr'])
        ) {
          validateError = 'No entries to save';
        }
      }

      if (
        state.getIn(['edit', 'activeProjects']).size === 0 ||
        state
          .getIn(['edit', 'activeProjects'])
          .every(
            (project) =>
              project.get('closed') ||
              project.get('tasks').every((task) => task.get('closed'))
          )
      ) {
        validateError = 'No active projects';
        // Allow editing not closed entries but prevent adding new
        saveDisabled =
          state.get('entries').every((entry) => entry.get('closed')) &&
          state
            .getIn(['edit', 'entries'])
            .every((entry) => entry.get('closed'));
      }

      if (state.getIn(['edit', 'hours']) > 24) {
        validateError = "Can't mark more than 24 hours per day";
      }

      // if (
      //   state.getIn(['edit', 'hours']) > data.get('defaultWorkHours') &&
      //   state.getIn(['edit', 'entries']).some((entry) =>
      //     data
      //       .get('projects')
      //       .filter((project) => project.get('id') === entry.get('projectId'))
      //       .first()
      //       .get('tasks')
      //       .filter((task) => task.get('id') === entry.get('taskId'))
      //       .first()
      //       .get('absence')
      //   )
      // ) {
      //   validateError = `Can't mark more than ${data.get(
      //     'defaultWorkHours'
      //   )} hours to a day with absence(s)`;
      // }

      const entriesWithChangedProjectOrTask = state
        .getIn(['edit', 'entries'])
        .filter((editedEntry) => {
          if (editedEntry.get('new') === true) {
            return true;
          }
          const d = state
            .get('entries')
            .find(
              (entry) => entry.get('id') === editedEntry.get('id'),
              null,
              editedEntry
            );

          return (
            d.get('projectId') !== editedEntry.get('projectId') ||
            d.get('taskId') !== editedEntry.get('taskId')
          );
        });

      if (
        entriesWithChangedProjectOrTask.some(
          (entry) =>
            state
              .getIn(['edit', 'activeProjects'])
              .find(
                (project) => project.get('id') === entry.get('projectId'),
                null,
                Map({ closed: false })
              )
              .get('closed') === true
        )
      ) {
        // iOS Safari doesn't care about disabled <select>'s ^____^
        validateError = "Can't select closed project";
      }
      if (
        entriesWithChangedProjectOrTask.some(
          (entry) =>
            state
              .getIn(['edit', 'activeProjects'])
              .find(
                (project) => project.get('id') === entry.get('projectId'),
                null,
                Map({ tasks: List([]) })
              )
              .get('tasks')
              .find(
                (task) => task.get('id') === entry.get('taskId'),
                null,
                Map({ closed: false })
              )
              .get('closed') === true
        )
      ) {
        // iOS Safari doesn't care about disabled <select>'s ^____^
        validateError = "Can't select closed task";
      }
      if (validateError) {
        return state
          .setIn(['edit', 'validateError'], validateError)
          .setIn(['edit', 'saveDisabled'], saveDisabled);
      }

      return state
        .deleteIn(['edit', 'validateError'])
        .setIn(['edit', 'saveDisabled'], false);
    }
    case ACTION_TYPES.UPDATE_HOURS_REMAINING: {
      if (!state.get('edit')) {
        return state;
      }

      const newState = state.updateIn(
        ['edit', 'activeProjects'],
        (activeProjects) =>
          activeProjects.map((activeProject) =>
            activeProject.update('tasks', (activeTasks) =>
              activeTasks.map((activeTask) => {
                const hoursRemaining = data
                  .get('projects')
                  .filter(
                    (project) => project.get('id') === activeProject.get('id')
                  )
                  .first()
                  .get('tasks')
                  .filter((task) => task.get('id') === activeTask.get('id'))
                  .first()
                  .get('hoursRemaining');
                if (activeTask.get('closed') || isNaN(hoursRemaining)) {
                  return activeTask;
                }
                return activeTask.set('hoursRemaining', hoursRemaining);
              })
            )
          )
      );

      return newState.updateIn(['edit', 'entries'], (entries) =>
        entries.map((entry) => {
          let hoursRemaining = newState
            .getIn(['edit', 'activeProjects'])
            .filter((project) => project.get('id') === entry.get('projectId'))
            .first()
            .get('tasks')
            .filter((task) => task.get('id') === entry.get('taskId'))
            .first()
            .get('hoursRemaining');

          if (entry.get('closed') || isNaN(hoursRemaining)) {
            return entry;
          }
          hoursRemaining -= entry.get('hours');

          let hoursFmt;
          if (Math.abs(hoursRemaining) >= 2 * data.get('defaultWorkHours')) {
            hoursFmt = `${Math.floor(
              hoursRemaining / data.get('defaultWorkHours')
            )} d`;
          } else {
            hoursFmt = `${hoursRemaining} h`;
          }
          return entry.set('hoursRemaining', hoursFmt);
        })
      );
    }
    case ACTION_TYPES.DELETE_DAY_EDIT_SCROLL_ANCHOR: {
      return state.setIn(['edit', 'scrollToTop'], false);
    }
    case ACTION_TYPES.SCROLL_TO_DAY: {
      if (state) {
        return state.set('scrollToDay', true);
      }

      return state;
    }
    case ACTION_TYPES.SCROLLED_TO_DAY: {
      return state.delete('scrollToDay');
    }
    default: {
      return state;
    }
  }
}
