/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import './EntryShow.scss';

export default class EntryShow extends PureComponent {
  render() {
    let leftSlot = '';
    let boldLeft = false;

    if (this.props.date) {
      const date = moment(this.props.date, 'YYYY-MM-DD');
      const now = moment(Date.now());

      leftSlot = date.format('ddd D.M.');
      if (now.format('YYYY-MM-DD') === this.props.date) {
        boldLeft = true;
      }
    }

    const disabled = this.props.disabled ? ' disabled' : '';

    return (
      <div className={`entry-show${disabled}`}>
        <span className={boldLeft ? 'left bold' : 'left'}>{leftSlot}</span>
        <span className="hours">
          {this.props.hours !== 0 ? this.props.hours : null}
        </span>
        <span className="project">{this.props.projectName}</span>
        <span className="task">{this.props.taskName}</span>
        <span className="description">{this.props.description}</span>
        <span className="right bold">{this.props.children}</span>
      </div>
    );
  }
}

EntryShow.propTypes = {
  disabled: PropTypes.bool,
  date: PropTypes.string,
  hours: PropTypes.number,
  projectName: PropTypes.string,
  taskName: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node
};
