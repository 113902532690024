import { string } from 'prop-types';
import useDateHours from './use-date-hours';
import { ABSENCE_TASK_BASE } from '../utils/config';

/**
 * Hook to check if user has absence entries for the date in edit or already saved
 *
 * @param {string} date
 * @returns {boolean}
 */
const useIsAbsencesToday = (date) => {
  const entriesToday = useDateHours(date);

  const todayEditTasks = entriesToday.edit.entries.map((e) => e.taskId);
  const todaySavedTasks = entriesToday.entries.map((e) => e.taskId);
  const todayTasks = [...todayEditTasks, ...todaySavedTasks];

  const absenceTasks = todayTasks.filter(
    (taskId) => taskId >= ABSENCE_TASK_BASE
  );

  return absenceTasks.length > 0;
};

useIsAbsencesToday.propTypes = { date: string };

export default useIsAbsencesToday;
