/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './TooltipError.scss';
import Tooltip from './Tooltip';

export default class TooltipError extends PureComponent {
  constructor() {
    super();

    this.toggleTooltip = this.toggleTooltip.bind(this);

    this.state = {
      show: false
    };
  }

  toggleTooltip(e) {
    e.preventDefault();
    this.setState({
      show: !this.state.show
    });
  }

  render() {
    let { description, text } = this.props;
    let children = null;
    if (description) {
      description = <div key="tooltip-error-description">{description}</div>;
    }

    if (text) {
      text = (
        <div className="error-text" key="tooltip-error-text">
          <div>{text}</div>
        </div>
      );
    }

    if (description || text) {
      children = [description, text];
    }

    return (
      <div className="tooltip-error">
        <i
          className="fa fa-exclamation-triangle"
          onMouseEnter={this.toggleTooltip}
          onMouseLeave={this.toggleTooltip}
          onTouchStart={this.toggleTooltip}
        />
        <Tooltip show={this.state.show}>{children}</Tooltip>
      </div>
    );
  }
}

TooltipError.propTypes = {
  description: PropTypes.string,
  text: PropTypes.string
};
