import React, { useState, useEffect } from 'react';
import Select2 from 'react-select2-wrapper';
import { number, shape, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import InformationTooltip from '../InformationTooltip';

import Switch from '../Switch';
import useDateHours from '../../hooks/use-date-hours';
import { ABSENCE_TASK_BASE } from '../../utils/config';
import { editEntry } from '../../reducers/day';

const infoTooltipText = `If you are a part-time employee, mark multi-day absences with
non-standard hours.

Enter your average daily hours to keep your hour
balance right. The default value is calculated based on your weekly
hours / working days. Only available with sick leave and parental
leave.`;

const createCustomAbsenceHoursRange = () => {
  const range = [];
  const maxAmount = 7.5;
  let cursor = 0.0;
  while (cursor <= maxAmount) {
    range.push(cursor);
    cursor += 0.25;
  }
  return range;
};

const AbsenceHoursEditor = ({ date, dayEntries }) => {
  // Check if today exists a non standard absence already, if yes init to that info immediately
  const initHours = () => {
    const entriesToday = dayEntries.toJS();
    const isNonDefaultAbsences = entriesToday.filter((entry) => {
      return (
        !entry.new && entry.projectId === ABSENCE_TASK_BASE && entry.hours !== 0
      );
    });

    if (isNonDefaultAbsences.length) {
      return isNonDefaultAbsences[0].hours;
    }

    return 0;
  };

  const [hoursSelected, setHoursSelected] = useState(initHours());
  const dateHours = useDateHours(date);
  const dispatch = useDispatch();
  const [isSwitchChecked, setIsSwitchChecked] = useState(hoursSelected !== 0);
  const absenceHoursRange = createCustomAbsenceHoursRange();

  const handleChange = (value) => {
    const hours = parseFloat(value);

    if (isNaN(hours)) {
      return;
    }

    setHoursSelected(hours);

    const todayAbsenceEntry = dateHours.edit?.entries?.filter(
      (e) => e.taskId >= ABSENCE_TASK_BASE
    )?.[0];

    dispatch(
      editEntry(date, todayAbsenceEntry.id, {
        hours
      })
    );
  };

  // Set custom absence default to 0
  useEffect(() => {
    const entriesToday = dayEntries.toJS();
    const isNewEntry = entriesToday.filter((entry) => entry.new);

    if (isNewEntry.length) {
      handleChange(0);
    }
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        alignContent: 'center',
        flexDirection: 'column',
        marginTop: '10px'
      }}
    >
      <div>
        <span>Non-standard hours</span>
        <InformationTooltip>
          <span>{infoTooltipText}</span>
        </InformationTooltip>
      </div>

      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          marginTop: '1rem'
        }}
      >
        <Switch
          date={date}
          checked={isSwitchChecked}
          onChange={(e) => setIsSwitchChecked(e.target.checked)}
        />
        <Select2
          value={hoursSelected}
          data={absenceHoursRange}
          options={{ minimumResultsForSearch: Infinity }}
          disabled={!isSwitchChecked}
          onChange={(e) => handleChange(e.target.value)}
        />
        <span
          style={{ display: 'flex', alignItems: 'center', marginLeft: '1rem' }}
        >
          Use for part-time absences
        </span>
      </div>
    </div>
  );
};

AbsenceHoursEditor.propTypes = {
  userDefaultHours: number,
  date: string,
  dayEntries: shape({})
};

export default AbsenceHoursEditor;
