import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';

class AbsenceLegend extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.toggleVisibility = this.toggleVisibility.bind(this);
  }

  renderLegends() {
    const { codes } = this.props;
    const center = Math.ceil(codes.length / 2);
    const left = codes.slice(0, center);
    const right = codes.slice(center, codes.length);

    const leftDivs = left.map((code) => (
      <div key={code[0]}>
        {code[0]}: {code[1]}
      </div>
    ));

    const rightDivs = right.map((code) => (
      <div key={code[0]}>
        {code[0]}: {code[1]}
      </div>
    ));

    return (
      <div className="absence--legend--list" onClick={this.toggleVisibility}>
        <div className="absence--legend--div">{leftDivs}</div>
        <div className="absence--legend--div">{rightDivs}</div>
      </div>
    );
  }

  toggleVisibility() {
    this.setState({
      open: !this.state.open
    });
  }

  render() {
    const { open } = this.state;

    return (
      <div className="absence--legend">
        <div
          className="absence--legend--visibility-toggle"
          onClick={this.toggleVisibility}
        >
          {`${open ? 'Hide' : 'Show'} absence legend`}
          <i
            className={`fa fa-caret-square-o-${open ? 'up' : 'down'} fa-2x`}
            style={{ marginLeft: '15px' }}
          />
        </div>
        {open && this.renderLegends()}
      </div>
    );
  }
}

AbsenceLegend.propTypes = {
  codes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};

export default AbsenceLegend;
