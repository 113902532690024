/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Map, List } from 'immutable';

import './Header.scss';
import logo from '../images/REMEDY-WORDMARK_White_RGB.svg';
import Statistics from './Statistics';
import Spinner from './Spinner';
import TooltipError from './TooltipError';
import User from './User';

export default class Header extends PureComponent {
  constructor(props) {
    super(props);

    this.onMenuButton = this.onMenuButton.bind(this);
  }

  onMenuButton() {
    this.props.openSideMenu(true);
  }

  render() {
    let statisticsDesktop = null;
    let statisticsMobile = null;
    let user = null;

    if (this.props.user.get('data')) {
      const currentUser = this.props.user;
      const cachedHourBalance = this.props.user.getIn([
        'data',
        'cachedHourBalance'
      ]);
      const crunchHours = cachedHourBalance.getIn(['flexHours']);
      const overworkHours = cachedHourBalance.getIn(['overworkHours']);
      // const crunchHours = this.props.user.getIn(['data', 'crunchHours']);
      // const overworkHours = this.props.user.getIn(['data', 'overworkHours']);
      const holidays = this.props.user.getIn(['data', 'holidaysLeft']);
      const additionalHolidays = this.props.user.getIn([
        'data',
        'additionalHolidaysLeft'
      ]);
      const longCareerHolidays = this.props.user.getIn([
        'data',
        'longCareerHolidaysLeft'
      ]);

      const plannedLeaves = this.props.user.getIn([
        'data',
        'plannedCrunchLeave'
      ]);

      const statistics = (
        <Statistics
          overworkHours={overworkHours}
          crunchHours={crunchHours}
          holidays={holidays}
          additionalHolidays={additionalHolidays}
          longCareerHolidays={longCareerHolidays}
          plannedCrunchHours={plannedLeaves}
        />
      );
      statisticsDesktop = (
        <div className="statistics-container desktop">{statistics}</div>
      );
      statisticsMobile = statistics;

      user = (
        <User
          user={currentUser}
          updateUser={this.props.updateUser}
          projects={this.props.projects}
        />
      );
    }

    let spinner = null;
    let menuButton = null;

    if (this.props.user.get('fetching')) {
      spinner = <Spinner />;
    } else if (this.props.user.get('fetchError')) {
      spinner = (
        <TooltipError
          description="Could not load data"
          text={this.props.user.getIn(['fetchError', 'error'])}
        />
      );
    } else if (this.props.user.getIn(['data', 'isSupervisor'])) {
      menuButton = (
        <div className="menu-button" onClick={this.onMenuButton}>
          <i className="fa fa-bars" />
        </div>
      );
    }

    return (
      <div className="top-header">
        <div className="content">
          <a className="title-container" href="/">
            <img className="logo" src={logo} alt="Timeegg logo" />
            {spinner}
          </a>
          {statisticsDesktop}
          {user}
        </div>
        <div className="statistics-container mobile">
          {menuButton}
          {statisticsMobile}
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  openSideMenu: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  updateUser: PropTypes.func.isRequired,
  projects: PropTypes.instanceOf(List)
};
