/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import moment from 'moment';

import './Month.scss';
import DayContainer from './Day/DayContainer';
import TooltipError from './TooltipError';

export default class Month extends Component {
  constructor() {
    super();

    this.scrollToTop = this.scrollToTop.bind(this);
    this.scrollToDay = this.scrollToDay.bind(this);
  }

  scrollToTop() {
    const { scrollAnchor, scrollAnchorAlignTop } = this.props;
    if (scrollAnchor === this.props.date) {
      this.node.scrollIntoView(scrollAnchorAlignTop);
      this.props.scrolledToAnchor();
    }
  }

  scrollToDay() {
    this.props.scrollToDay(this.props.month.get('missingEntries'));
  }

  componentDidMount() {
    this.scrollToTop();
  }

  componentDidUpdate() {
    this.scrollToTop();
  }

  render() {
    const monthText = moment(this.props.date, 'YYYY-MM').format('MMMM YYYY');
    const days = this.props.month
      .get('days')
      .entrySeq()
      .sort((a, b) => {
        // sort to descending order
        if (a[0] < b[0]) {
          return 1;
        }
        return -1;
      })
      .map(([key, day]) => <DayContainer key={key} date={key} day={day} />)
      .toArray();

    let missingEntries = null;
    if (this.props.month.get('missingEntries')) {
      missingEntries = (
        <div className="missing-entries" onClick={this.scrollToDay}>
          <TooltipError text="Missing entries!" />
        </div>
      );
    }

    return (
      <section
        className="month"
        ref={(node) => {
          this.node = node;
        }}
      >
        <header>
          <div className="header-text">
            <h2>{monthText}</h2>
          </div>
          <div className="balance-text">
            {missingEntries}
            <span className="hours" title="Hours accumulated in this month">
              {`${this.props.month.get('hours')} h / ${this.props.month.get(
                'workHours'
              )} h`}
            </span>
          </div>
        </header>
        {days}
      </section>
    );
  }
}

Month.propTypes = {
  date: PropTypes.string.isRequired,
  month: PropTypes.instanceOf(Map).isRequired,
  scrollAnchor: PropTypes.string,
  scrollAnchorAlignTop: PropTypes.bool,
  scrolledToAnchor: PropTypes.func.isRequired,
  scrollToDay: PropTypes.func.isRequired
};
