/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import moment from 'moment';

import './DayEdit.scss';
import '../Buttons.scss';
import EntryEdit from '../Entry/EntryEdit';
import CircleButton from '../CircleButton';
import Spinner from '../Spinner';
import TooltipError from '../TooltipError';
import { isMobile, isMobileOrTablet } from '../../utils/mobile';
import {
  UNPAID_LEAVE_TASK_ID,
  OTHER_PAID_LEAVE_TASK_ID
} from '../../utils/config';
import AbsenceHoursEditor from './AbsenceHourEditor';
import useIsAbsencesToday from '../../hooks/use-is-absences-today';
import useUserDefaultHours from '../../hooks/use-user-default-hours';

const DayEdit = ({
  day,
  deleteScrollAnchor,
  deleteEntry,
  saveDay,
  cancelEditDay,
  editEntry,
  user,
  holidays,
  months,
  scrolledToDay,
  date: propsDate,
  addEntry
}) => {
  const userDefaultHours = useUserDefaultHours();
  const isAbsencesToday = useIsAbsencesToday(propsDate);

  const scrollAnchor = useRef(null);
  const submitRef = useRef(null);

  const cancel = (e) => {
    scrollToTop();
    e.preventDefault();
    cancelEditDay();
  };

  const save = (e) => {
    if (!validateMandatoryDescriptions()) {
      e.preventDefault();
      return;
    }

    scrollToTop();
    e.preventDefault();
    saveDay();
  };

  const validateMandatoryDescriptions = () => {
    let valid = true;

    day.get('entries').forEach((entry) => {
      const taskId = entry.get('taskId');
      const description = entry.get('description');

      if (
        taskId === UNPAID_LEAVE_TASK_ID ||
        taskId === OTHER_PAID_LEAVE_TASK_ID
      ) {
        if (description.length < 5) {
          valid = false;
        }
      }
    });

    return valid;
  };

  const scrollToTop = () => {
    if (isMobile()) {
      scrollAnchor.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToDay = () => {
    if (scrollToDay) {
      scrollAnchor.current?.scrollIntoView({
        behavior: 'smooth'
      });
      scrolledToDay();
    }
  };

  useEffect(() => {
    scrollToTop();
    scrollToDay();
  }, []);

  useEffect(() => {
    if (day.get('scrollToTop')) {
      scrollAnchor.current?.scrollIntoView({
        behavior: 'smooth'
      });
      deleteScrollAnchor();
    }
    scrollToDay();
  }, []);

  const date = moment(propsDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
  const errorPadding =
    day.get('entries').filter((entry) => entry.get('error')).size > 0;

  const mobile = isMobileOrTablet();

  const entries = day
    .get('entries')
    .map((entry, i) => {
      return (
        <EntryEdit
          key={`${date}-entry-edit-${i}`}
          entry={entry}
          date={date}
          projects={day.get('activeProjects')}
          editEntry={editEntry}
          deleteEntry={deleteEntry}
          errorPadding={errorPadding}
          isMobile={mobile}
          user={user}
          months={months}
          holidays={holidays}
          submitRef={submitRef}
        />
      );
    })
    .toArray();

  let info = null;
  const saveDisabled = day.get('saveDisabled');
  const totalDisabled = saveDisabled || !validateMandatoryDescriptions();

  const isUserPartTime = userDefaultHours !== 7.5;

  let formElem = (
    <form onSubmit={save}>
      {entries}
      <div className="buttons">
        {!isAbsencesToday && (
          <div className="add-row">
            <CircleButton positive stroke onClick={addEntry} />
            <span className="add-row-text" onClick={addEntry}>
              Add row
            </span>
          </div>
        )}

        {isUserPartTime && isAbsencesToday && (
          <AbsenceHoursEditor
            date={propsDate}
            dayEntries={day.get('entries')}
          />
        )}

        {/* Empty div to move Cancel & Save buttons to normal position */}
        {!isUserPartTime && isAbsencesToday && <div />}

        <div className="submit">
          <button className="cancel-button" onClick={cancel} tabIndex={0}>
            Cancel
          </button>
          <button
            className="save-button"
            type="submit"
            disabled={totalDisabled}
            ref={submitRef}
            tabIndex={0}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );

  if (day.get('error')) {
    info = <TooltipError description={day.get('error')} />;
  }

  if (day.get('validateError')) {
    info = <TooltipError description={day.get('validateError')} />;
  }

  if (day.get('saving')) {
    info = <Spinner />;
    formElem = null;
  }

  return (
    <div className="day-edit">
      <div className="scroll-anchor" ref={scrollAnchor} />
      <div className="header" onClick={cancel}>
        <div className="date">
          <span className="date-fmt">{date}</span>
          {info}
        </div>
        <span>{`${day.get('hours')} h`}</span>
      </div>
      {formElem}
    </div>
  );
};

DayEdit.propTypes = {
  date: PropTypes.string.isRequired,
  day: PropTypes.instanceOf(Map).isRequired,
  months: PropTypes.instanceOf(Map).isRequired,
  holidays: PropTypes.instanceOf(List),
  addEntry: PropTypes.func.isRequired,
  editEntry: PropTypes.func.isRequired,
  deleteEntry: PropTypes.func.isRequired,
  cancelEditDay: PropTypes.func.isRequired,
  saveDay: PropTypes.func.isRequired,
  deleteScrollAnchor: PropTypes.func.isRequired,
  scrollToDay: PropTypes.bool,
  scrolledToDay: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map)
};

export default DayEdit;
