/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import $ from 'jquery';
import moment from 'moment';
import Select2 from 'react-select2-wrapper';
import './EntryEdit.scss';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import CircleButton from '../CircleButton';
import TooltipError from '../TooltipError';
import BigError from '../BigError';
import NativeSelect from '../NativeSelect';
import MobileDateSelect from '../MobileDateSelect';
import ReadOnlyInput from '../ReadOnlyInput';
import InformationTooltip from '../InformationTooltip';
import {
  UNPAID_LEAVE_TASK_ID,
  OTHER_PAID_LEAVE_TASK_ID,
  BUSINESS_TRIP_TASK_ID
} from '../../utils/config';

export default class EntryEdit extends PureComponent {
  constructor(props = {}) {
    super();

    this.delete = this.delete.bind(this);
    this.onHoursChange = this.onHoursChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onStartChange = this.onStartChange.bind(this);
    this.onEndChange = this.onEndChange.bind(this);
    this.onProjectChange = this.onProjectChange.bind(this);
    this.onTaskChange = this.onTaskChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.appendSelectors = this.appendSelectors.bind(this);
    this.resizeDescription = this.resizeDescription.bind(this);
    this.handleDescriptionKeyPress = this.handleDescriptionKeyPress.bind(this);

    // Copy projects so saving another day doesn't affect other open days
    this.state = {
      projects: props.projects
    };
  }

  delete() {
    this.props.deleteEntry(this.props.entry.get('id'));
  }

  onHoursChange(event) {
    const hours = parseFloat(event.target.value);
    if (isNaN(hours)) {
      return;
    }
    this.props.editEntry(this.props.entry.get('id'), {
      hours
    });
  }

  onDateChange(event) {
    const untilDate = moment(event).format('YYYY-MM-DD');
    this.props.editEntry(this.props.entry.get('id'), {
      date: this.props.date,
      untilDate
    });
  }

  timeOptions() {
    return Array.from(
      {
        length: (24 * 60) / 15
      },
      (v, i) => {
        let h = Math.floor((i * 15) / 60);
        let m = i * 15 - h * 60;

        // pad zeros
        if (m < 10) m = `0${m}`;
        if (h < 10) h = `0${h}`;
        const time = `${h}:${m}`;
        return { id: time, text: time };
      }
    );
  }

  optionsAfter(startTime) {
    const options = this.timeOptions().filter((time) => time.text > startTime);
    return options;
  }

  optionsBefore(endTime) {
    const options = this.timeOptions().filter((time) => time.text < endTime);
    return options;
  }

  onStartChange(event) {
    const { entry } = this.props;
    const startTime = moment(entry.get('startTime'));
    startTime.hour(event.target.value.split(':')[0]);
    startTime.minutes(event.target.value.split(':')[1]);
    startTime.seconds(0);
    startTime.milliseconds(0);

    // If start time is after end time, change end time equal to start time to avoid negative times.
    if (startTime.toDate() >= this.props.entry.get('endTime')) {
      this.props.editEntry(this.props.entry.get('id'), {
        endTime: startTime.toDate()
      });
    }

    this.props.editEntry(this.props.entry.get('id'), {
      startTime: startTime.toDate()
    });
  }

  onEndChange(event) {
    const { entry } = this.props;
    const endTime = moment(entry.get('endTime'));
    endTime.hour(event.target.value.split(':')[0]);
    endTime.minutes(event.target.value.split(':')[1]);
    endTime.seconds(0);
    endTime.milliseconds(0);

    this.props.editEntry(this.props.entry.get('id'), {
      endTime: endTime.toDate()
    });
  }

  onProjectChange(event) {
    const projectId = parseFloat(event.target.value);
    if (isNaN(projectId)) {
      return;
    }

    const absence = this.props.projects
      .filter((project) => project.get('id') === projectId)
      .first()
      .get('absence');

    if (absence) {
      this.props.editEntry(this.props.entry.get('id'), {
        projectId,
        untilDate: this.props.date,
        date: this.props.date
      });
    } else {
      this.props.editEntry(this.props.entry.get('id'), {
        projectId,
        untilDate: undefined
      });
    }
  }

  onTaskChange(event) {
    const taskId = parseFloat(event.target.value);
    if (isNaN(taskId)) {
      return;
    }

    this.props.editEntry(this.props.entry.get('id'), {
      taskId
    });
  }

  onDescriptionChange(event) {
    this.props.editEntry(this.props.entry.get('id'), {
      description: event.target.value
    });
  }

  handleDescriptionKeyPress(e) {
    // shift + enter gives new line
    // enter submits form
    if (e.key === 'Enter' && e.shiftKey === false) {
      this.props.submitRef.current.click();
    }
  }

  appendSelectors() {
    // Dirty hack, please forgive me.
    // This replaces the default selection arrows with FontAwesome arrows.
    // The default ones are hidden with css
    let elements = $(this.node).find('.select2-selection__arrow');

    for (let i = 0; i < elements.length; i++) {
      const element = $(elements[i]);
      if (element.find('.fa-angle-down').length === 0) {
        element.append('<i class="fa fa-angle-down"></i>');
      }
    }

    elements = $(this.node).find('.date-input-wrapper');

    for (let i = 0; i < elements.length; i++) {
      const element = $(elements[i]);
      if (element.find('.fa-angle-down').length === 0) {
        element.append('<i class="fa fa-angle-down"></i>');
      }
    }
  }

  resizeDescription() {
    // Dirty hack, please forgive me.
    // http://stephanwagner.me/auto-resizing-textarea
    // Doesn't work properly with long lines,
    // works perfectly with multiple lines
    const $e = $(this.description);
    const e = $e[0];

    if (!e) {
      return;
    }

    const offset = e.offsetHeight - e.clientHeight;
    $e.css('height', 'auto').css('height', e.scrollHeight + offset);
  }

  getDateOptions(list) {
    let options = List();

    list = list.map((d) => {
      return {
        id: d.get('id'),
        text: d.get('name')
      };
    });

    list = list.sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      return 1;
    });

    options = options.push({
      text: 'end date',
      children: list.toArray()
    });

    return options.toArray();
  }

  getSelectOptions(list) {
    const { user } = this.props;

    let options = list.map((d) => {
      const result = {
        id: d.get('id'),
        text: d.get('name')
      };
      if (d.get('closed') && !user.get('hrLarp')) {
        return { ...result, disabled: true };
      }
      return result;
    });

    options = options.filterNot((d) => d.disabled);

    return options.toArray();
  }

  componentDidMount() {
    this.appendSelectors();
    // this.resizeDescription();

    this.props.editEntry(this.props.entry.get('id'), {
      startTime: moment(this.props.entry.get('startTime')).toDate(),
      endTime: moment(this.props.entry.get('endTime')).toDate()
    });
  }

  componentDidUpdate() {
    this.appendSelectors();
    // this.resizeDescription();
  }

  renderTooltip() {
    return (
      <div>
        <div>
          <h4>Flex leave</h4>
          Mark whole day flex leaves with absence type “flex leave”. If you
          choose to work a shorter day, you mark the actual working hours for
          the specific day.
        </div>
        <div>
          <h4>Holiday</h4>
          Use this absence type to mark your annual holidays, extra holidays and
          possible long career reward.
        </div>
        <div>
          <h4>Long Career Holiday</h4>
          Long career leave is a paid additional leave. It's purpose is to
          recognize those with long careers at Remedy.
        </div>
        <div>
          <h4>Additional Holiday</h4>
          Additional holiday is additional paid leave granted by Remedy, other
          than annual holiday or long career leave.
        </div>
        <div>
          <h4>Sick leave</h4>
          Use this absence type to mark whole sick leave days. If you need to
          leave in the middle of the day, please mark it as normal working day.
          Also remember to inform your supervisor, project lead and workmates
          that you are away.
        </div>
        <div>
          <h4>Sick child leave</h4>
          Use this absence type if you need to stay home to take care of your
          sick child. If you need to leave in the middle of the day, please mark
          it as normal working day. Also remember to inform your supervisor,
          project lead and workmates that you are away.
        </div>
        <div>
          <h4>Business trip</h4>
          Use this absence type to mark the days you are on business trip. In
          case you are on business trip on a weekend please mark also those days
          so we can calculate the amount of your daily allowance correctly. Mark
          only whole days. In case you leave on a business trip in the middle of
          the working day mark that day as a normal working day (7, 5 h).
        </div>
        <div>
          <h4>Training day</h4>
          Use this absence type to mark training/conference etc. (only whole
          days). If you are on half day training please mark it as normal
          working day.
        </div>
        <div>
          <h4>Unpaid leave</h4>
          Use this absence type to mark unpaid leaves. Unpaid days are deducted
          from your next month’s salary.
        </div>
        <div>
          <h4>Moving day</h4>
          You can take one day off per move. If you move several time per year,
          the maximum amount of move days is 2 day per year.
        </div>
        <div>
          <h4>Paternity leave</h4>
          You can stay 18 days home at same time with the child’s mom after your
          baby is born. Please note that these 18 days includes also Saturdays
          but you don’t have to mark those to TimeEgg.
        </div>
        <div>
          <h4>Maternity leave</h4>
          Use this absence type to mark your maternity leave days. Please
          contact HR to check the length of your maternity leave.
        </div>
        <div>
          <h4>Parental leave</h4>
          After maternity leave the mother, father or both (not at the same
          time) can be on a parental leave until the baby is nine months old.
          Please contact HR to check the length of your parental leave.
        </div>
        <div>
          <h4>Child care leave</h4>
          After parental leave, you can be on child care leave until your baby
          turns three. Please contact HR in this case in advance.
        </div>
        <div>
          <h4>Study leave</h4>
          Use this absence type to mark your possible study leave. Please
          contact HR in advance.
        </div>
        <div>
          <h4>Pregnancy leave</h4>
          Use this absence type when you start your pregnancy leave. Pregnancy
          leave starts before the estimated birth date of your baby. Contact HR
          to discuss your family leaves in advance.
        </div>
        <div>
          <h4>Carer's leave</h4>
          Carer's leave is a paid leave intended for situations where employee
          needs to leave work temporarily to provide full-time care and
          attention for a close person or someone in their close family being
          severely sick. Close family or a close person means in this context
          spouse, partner, child, spouse's child or someone else whom the
          employee lives in a family-like conditions with.
        </div>
        <div>
          <h4>Study leave</h4>
          Use this absence type to mark your possible study leave. Please
          contact HR in advance.
        </div>
        <div>
          <h4>Other paid leave</h4>
          Use this absence type to mark other paid leave such as family
          occasions as for example wedding day, near relative’s funeral etc.
          Please contact HR to check that company is paying the leave.
        </div>
      </div>
    );
  }

  render() {
    const { entry, user } = this.props;
    const useHoursInput = user.get('useHoursInput');

    if (entry.get('deleted') && !entry.get('error')) {
      return null;
    }

    const hours = entry.get('hours');
    const projectId = entry.get('projectId');
    const taskId = entry.get('taskId');
    const description = entry.get('description');
    const latestDescription = entry.get('latestDescription');
    const startTime = moment(entry.get('startTime')).format('HH:mm');
    const endTime = moment(entry.get('endTime')).format('HH:mm');

    const select2Options = {
      minimumResultsForSearch: 1
    };

    const hourOptions = Array.apply(null, new Array(72)).map((val, i) => {
      const value = (i + 1) * 0.25;
      return {
        id: value,
        text: value
      };
    });

    const { projects } = this.props;
    const project = this.props.projects
      .filter((project) => project.get('id') === projectId)
      .first();

    if (!project) {
      return <BigError description="Cannot find the project" />;
    }

    const tasks = project.get('tasks');
    const projectOptions = this.getSelectOptions(
      projects,
      'project',
      'projects'
    );

    // Sort by ID so we can easily change order by manipulating task IDs
    const taskOptions = this.getSelectOptions(tasks, 'task', 'tasks').sort(
      (a, b) => a.id - b.id
    );

    let error = null;
    if (entry.get('error')) {
      let editType = 'update';
      if (entry.get('new')) {
        editType = 'create';
      }
      if (entry.get('deleted')) {
        editType = 'delete';
      }

      error = (
        <TooltipError
          description={`Could not ${editType} entry`}
          text={entry.getIn(['error', 'error'])}
        />
      );
    } else if (this.props.errorPadding) {
      error = <div className="error-padding" />;
    }

    const disabled =
      (entry.get('deleted') || entry.get('closed')) && !user.get('hrLarp');
    const hoursDisabled = project.get('absence');

    let hoursSelect = (
      <div className="input-group">
        <label className="input-label">Hours</label>
        <Select2
          className="hours"
          value={hours}
          data={hourOptions}
          onChange={this.onHoursChange}
          options={{ minimumResultsForSearch: Infinity }}
          disabled={disabled || hoursDisabled}
        />
      </div>
    );
    let startSelect = (
      <div className="input-group">
        <label className="input-label">Start</label>
        <Select2
          className="times"
          value={startTime}
          data={this.optionsBefore(endTime)}
          onChange={this.onStartChange}
          options={{ minimumResultsForSearch: Infinity }}
          disabled={disabled || hoursDisabled}
        />
      </div>
    );
    let endSelect = (
      <div className="input-group">
        <label className="input-label">End</label>
        <Select2
          className="times"
          value={endTime}
          data={this.optionsAfter(startTime)}
          onChange={this.onEndChange}
          options={{ minimumResultsForSearch: Infinity }}
          disabled={disabled || hoursDisabled}
        />
      </div>
    );
    let projectsSelect = (
      <div className="input-group">
        <label className="input-label">Project</label>
        <Select2
          className="projects"
          value={projectId}
          data={projectOptions}
          onChange={this.onProjectChange}
          options={select2Options}
          disabled={disabled}
        />
      </div>
    );
    let tasksSelect = (
      <div className="input-group">
        <label className="input-label">
          Task
          <InformationTooltip>{this.renderTooltip()}</InformationTooltip>
        </label>
        <Select2
          className="select-tasks"
          value={taskId}
          data={taskOptions}
          onChange={this.onTaskChange}
          options={{ ...select2Options }}
          disabled={disabled}
        />
      </div>
    );

    const holidays = this.props.holidays || List();
    let disabledDays = [{ before: new Date(this.props.date) }];

    if (taskId !== BUSINESS_TRIP_TASK_ID) {
      disabledDays.push({ daysOfWeek: [0, 6] });
      disabledDays = disabledDays.concat(
        holidays.map((day) => new Date(day.get('date'))).toArray()
      );
    }

    let dateSelect = (
      <div className="input-group">
        <label className="input-label">End date</label>
        {entry.get('new') ? (
          <DayPickerInput
            className="date-picker"
            name="end-date"
            placeholder="YYYY-MM-DD"
            format="YYYY-MM-DD"
            value={this.props.entry.get('untilDate') || this.props.date}
            onDayChange={this.onDateChange}
            component={ReadOnlyInput}
            dayPickerProps={{
              firstDayOfWeek: 1,
              disabledDays
            }}
          />
        ) : (
          <input
            className="disabled"
            value={this.props.entry.get('untilDate') || this.props.date}
            disabled
          />
        )}
      </div>
    );

    if (this.props.isMobile === true) {
      hoursSelect = (
        <div className="input-group">
          <label className="input-label">Hours</label>
          <NativeSelect
            className="hours"
            value={hours}
            data={hourOptions}
            onChange={this.onHoursChange}
            disabled={disabled || hoursDisabled}
          />
        </div>
      );
      startSelect = (
        <div className="input-group">
          <label className="input-label">Start</label>
          <NativeSelect
            className="times"
            value={startTime}
            data={this.optionsBefore(endTime)}
            onChange={this.onStartChange}
            options={{ minimumResultsForSearch: Infinity }}
            disabled={disabled || hoursDisabled}
          />
        </div>
      );
      endSelect = (
        <div className="input-group">
          <label className="input-label">End</label>
          <NativeSelect
            className="times"
            value={endTime}
            data={this.optionsAfter(startTime)}
            onChange={this.onEndChange}
            options={{ minimumResultsForSearch: Infinity }}
            disabled={disabled || hoursDisabled}
          />
        </div>
      );
      projectsSelect = (
        <div className="input-group">
          <label className="input-label">Project</label>
          <NativeSelect
            className="projects"
            value={projectId}
            data={projectOptions}
            onChange={this.onProjectChange}
            disabled={disabled}
          />
        </div>
      );
      tasksSelect = (
        <div className="input-group">
          <label className="input-label">Task</label>
          <NativeSelect
            className="select-tasks"
            value={taskId}
            data={taskOptions}
            onChange={this.onTaskChange}
            disabled={disabled}
          />
        </div>
      );
      dateSelect = (
        <div className="input-group">
          <label className="input-label">End date</label>
          {entry.get('new') ? (
            <MobileDateSelect
              className="date-picker-mobile"
              value={this.props.entry.get('untilDate') || this.props.date}
              onChange={this.onDateChange}
              dayPickerProps={Map({
                firstDayOfWeek: 1,
                disabledDays
              })}
            />
          ) : (
            <input
              className="disabled"
              value={this.props.entry.get('untilDate') || this.props.date}
              disabled
            />
          )}
        </div>
      );
    }

    const mandatoryDescriptionLabel = (
      <div>
        <i className="fa fa-info fa-lg" />

        <div className="entry-edit--mandatory-description--text">
          Please provide a short description, minimum 5 characters
        </div>
      </div>
    );

    const mandatoryDescriptionTaskID = (taskId) => {
      return (
        taskId === UNPAID_LEAVE_TASK_ID || taskId === OTHER_PAID_LEAVE_TASK_ID
      );
    };

    const singleSelect = project.get('absence') // use date selector if absence project selected
      ? dateSelect
      : hoursSelect;

    const onFocus = (e) => e.target.select();

    return (
      <div
        className={`entry-edit${disabled ? ' disabled' : ''}`}
        ref={(node) => {
          this.node = node;
        }}
      >
        {useHoursInput || project.get('absence') ? (
          singleSelect
        ) : (
          <span className="times-container">
            {startSelect} <div className="divider times-divider" /> {endSelect}
          </span>
        )}
        <div className="divider" />

        {projectsSelect}
        <div className="divider" />
        {tasksSelect}
        <div
          className="divider task-description"
          ref={(node) => {
            this.scrollAnchor = node;
          }}
        />
        <div className="input-group-description">
          <label className="input-label">Description</label>
          <textarea
            className="description"
            ref={(node) => {
              this.description = node;
            }}
            onFocus={onFocus}
            rows="1"
            placeholder={latestDescription}
            value={description}
            onChange={this.onDescriptionChange}
            disabled={disabled}
            onKeyPress={this.handleDescriptionKeyPress}
          />
          {mandatoryDescriptionTaskID(taskId) && mandatoryDescriptionLabel}
        </div>
        {this.props.isMobile === true ? null : <div className="divider" />}
        <div className="no-label">
          <CircleButton
            positive={false}
            onClick={this.delete}
            disabled={disabled}
          />
          {error}
        </div>
      </div>
    );
  }
}

EntryEdit.propTypes = {
  months: PropTypes.instanceOf(Map).isRequired,
  date: PropTypes.string.isRequired,
  entry: PropTypes.instanceOf(Map).isRequired,
  holidays: PropTypes.instanceOf(List),
  projects: PropTypes.instanceOf(List).isRequired,
  editEntry: PropTypes.func.isRequired,
  deleteEntry: PropTypes.func.isRequired,
  errorPadding: PropTypes.bool,
  isMobile: PropTypes.bool,
  user: PropTypes.instanceOf(Map),
  submitRef: PropTypes.instanceOf(Object).isRequired
};
