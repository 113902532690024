import { useEffect } from 'react';

/**
 * Hook to listen for outside-of-component clicks
 * @param {reactReference} menuRef
 * @param {boolean} setIsOpen
 * @returns
 */
const useHideOnOutsideClick = (ref, hide) => {
  useEffect(() => {
    if (!ref?.current) return;

    const listener = (evt) => {
      if (ref.current.contains(evt.target)) return;
      hide();
    };

    const events = [`click`, `touchstart`];

    events.forEach((type) => {
      document.addEventListener(type, listener);
    });

    return () => {
      events.forEach((type) => {
        document.removeEventListener(type, listener);
      });
    };
  }, [ref]);
};

export default useHideOnOutsideClick;
