import './styles.css';
import React from 'react';
import PropTypes from 'prop-types';
import './Timeline.css';
import moment from 'moment';
import TimelineView from 'react-calendar-timeline';

const TimelineComponent = ({
  filteredUsers,
  absences,
  startTime,
  endTime,
  onBoundsChange,
  handleTimelineZoom,
  companyHolidays,
  holidays
}) => {
  const verticalLineClassNamesForTime = (start, end) => {
    const classNames = [];

    // disable holiday styles in month view
    if (showWeeksNumbers) {
      classNames.push('month--view-week');
    }

    // today style
    if (moment().isSame(start, 'day') && moment().isSame(end, 'day')) {
      classNames.push('today');
    }

    // holiday style

    if (holidays.includes(moment(start).format('YYYY-MM-DD'))) {
      classNames.push('holiday');
    }

    const today = moment().format('YYYY-MM-DD');
    if (companyHolidays.includes(today)) {
      classNames.push('holiday');
    }

    return classNames;
  };

  const horizontalLineClassNamesForGroup = (group) => {
    if (group.title === 'Total absences') {
      return ['total'];
    }
  };

  // const monthDelta = 1000 * 60 * 60 * 24 * 30;
  const showWeeksNumbers = false;

  const headerLabelFormats = {
    yearShort: 'YY',
    yearLong: 'YYYY',
    monthShort: 'MM/YY',
    monthMedium: 'MM/YYYY',
    monthMediumLong: 'MMM YYYY',
    monthLong: 'MMMM YYYY',
    dayShort: 'L',
    dayLong: 'dddd, LL',
    hourShort: 'HH',
    hourMedium: 'HH:00',
    hourMediumLong: 'L, HH:00',
    hourLong: 'dddd, LL, HH:00',
    time: 'LLL'
  };

  const subHeaderLabelFormats = {
    yearShort: 'YY',
    yearLong: 'YYYY',
    monthShort: 'MM',
    monthMedium: 'MMM',
    monthLong: 'MMMM',
    dayShort: 'D',
    dayMedium: 'dd D',
    dayMediumLong: 'ddd, Do',
    dayLong: 'dddd, Do',
    hourShort: 'HH',
    hourLong: 'HH:00',
    minuteShort: 'mm',
    minuteLong: 'HH:mm'
  };

  const handleBoundsChange = (start, end) => {
    onBoundsChange(start, end);
  };

  const handleTimeChange = (start, end, updateScrollCanvas) => {
    handleTimelineZoom(start, end);
    updateScrollCanvas(start, end);
  };

  return (
    <div className="mainpage--timeline-container">
      <TimelineView
        groups={filteredUsers}
        items={absences}
        visibleTimeStart={startTime}
        visibleTimeEnd={endTime}
        minZoom={1000 * 60 * 60 * 24 * 30}
        maxZoom={1000 * 60 * 60 * 24 * 30 + 1}
        timeSteps={{
          second: 1,
          minute: 1,
          hour: 1,
          day: 1,
          month: 1
        }}
        onTimeChange={handleTimeChange}
        onBoundsChange={handleBoundsChange}
        verticalLineClassNamesForTime={verticalLineClassNamesForTime}
        horizontalLineClassNamesForGroup={horizontalLineClassNamesForGroup}
        headerLabelFormats={headerLabelFormats}
        subHeaderLabelFormats={subHeaderLabelFormats}
      />
    </div>
  );
};

TimelineComponent.propTypes = {
  holidays: PropTypes.arrayOf(PropTypes.string).isRequired,
  filteredUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  absences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  onBoundsChange: PropTypes.func.isRequired,
  handleTimelineZoom: PropTypes.func.isRequired,
  companyHolidays: PropTypes.arrayOf(PropTypes.string).isRequired
};

class Timeline extends React.Component {
  componentDidMount() {
    const { handleTimelineZoom } = this.props;

    const weekStart = moment().startOf('week').valueOf();
    const weekEnd = moment().endOf('week').valueOf();

    /**
     * react-calendar-timeline counts widths wrong
     * this fixes them
     */
    setTimeout(() => {
      window.dispatchEvent(new window.Event('resize'));

      handleTimelineZoom(weekStart, weekEnd);
    }, 100);
  }

  componentDidUpdate(prevProps) {
    const { endTime, startTime } = this.props;

    if (endTime === prevProps.endTime || startTime === prevProps.startTime) {
      /**
       * react-calendar-timeline counts widths wrong
       * this fixes them
       */
      setTimeout(() => {
        window.dispatchEvent(new window.Event('resize'));
      }, 100);
    }
  }

  render() {
    return <TimelineComponent {...this.props} />;
  }
}

Timeline.propTypes = {
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  handleTimelineZoom: PropTypes.func.isRequired
};

export default Timeline;
