/* eslint-disable global-require */
/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

// TODO: the Redux store implementation should really be migrated to redux-toolkit
import { createStore, applyMiddleware, compose } from 'redux';
import { install } from 'redux-loop';
import createLogger from 'redux-logger';

import reducers from '../reducers';

export default function configureStore(initialState) {
  const logger = createLogger({
    collapsed: true,

    // only log in development mode
    predicate: () => true,

    // transform immutable state to plain objects
    stateTransformer: (state) => state.toJS(),

    // transform immutable action payloads to plain objects
    actionTransformer: (action) =>
      action && action.payload && action.payload.toJS
        ? { ...action, payload: action.payload.toJS() }
        : action
  });

  let enhancer;

  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancer = compose(
      applyMiddleware(logger),
      install(),
      window.__REDUX_DEVTOOLS_EXTENSION__()
    );
  } else {
    enhancer = compose(applyMiddleware(logger), install());
  }

  const store = createStore(reducers, initialState, enhancer);

  if (
    process.env.NODE_ENV !== 'production' &&
    process.env.NODE_ENV !== 'test' &&
    module.hot
  ) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
