/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Map, List } from 'immutable';
import Employee from './Employee';
import Exporter from './Exporter';
import Spinner from '../Spinner';
import { clearBalanceChange, saveDelta } from '../../reducers/balanceChange';
import { fetchUser } from '../../reducers/user';

export class SupervisorMenu extends PureComponent {
  constructor() {
    super();

    this.showExporter = this.showExporter.bind(this);
    this.hideExporter = this.hideExporter.bind(this);

    this.state = {
      showExporter: false
    };
  }

  showExporter(e) {
    e.preventDefault();
    this.setState({
      showExporter: !this.state.showExporter
    });
  }

  hideExporter() {
    this.setState({
      showExporter: false
    });
  }

  render() {
    const teamMembers = this.props.teamMembers.get('data') || List();
    const isHrOrFina =
      this.props.user.getIn(['data', 'isHr']) ||
      this.props.user.getIn(['data', 'isFina']);

    if (!isHrOrFina && teamMembers.size === 0) {
      return null;
    }

    const teamList = (
      <div className="team-list">
        {teamMembers
          .sort((a, b) => {
            if (b.get('disabled') || a.get('firstname') < b.get('firstname'))
              return -1;
            if (a.get('disabled') || a.get('firstname') > b.get('firstname'))
              return 1;
            return 0;
          })
          .map((user, i) => (
            <Employee
              hr={this.props.user.getIn(['data', 'isHr'])}
              user={user}
              key={i}
              clearBalanceChange={this.props.clearBalanceChange}
              balanceChange={this.props.balanceChange}
              fetchUser={this.props.fetchUser}
              saveDelta={this.props.saveDelta}
            />
          ))}
      </div>
    );

    return (
      <div>
        <div className="header-text">
          <h2>
            Team{' '}
            <i
              className="fa fa-download button ignore-react-onclickoutside"
              aria-hidden="true"
              onClick={this.showExporter}
            />
          </h2>
          {this.state.showExporter ? (
            <Exporter
              hide={this.hideExporter}
              hr={this.props.user.getIn(['data', 'isHr'])}
              fina={this.props.user.getIn(['data', 'isFina'])}
            />
          ) : null}
        </div>
        {teamMembers.size === 0 ? (
          <div className="spinner-container">
            <Spinner />
          </div>
        ) : (
          teamList
        )}
      </div>
    );
  }
}

SupervisorMenu.propTypes = {
  user: PropTypes.instanceOf(Map).isRequired,
  teamMembers: PropTypes.instanceOf(Map).isRequired,
  saveDelta: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  clearBalanceChange: PropTypes.func.isRequired,
  balanceChange: PropTypes.instanceOf(Map)
};

export default connect(
  (state) => ({
    user: state.get('user'),
    teamMembers: state.get('teamMembers'),
    balanceChange: state.get('balanceChange')
  }),
  (dispatch) => ({
    clearBalanceChange() {
      dispatch(clearBalanceChange());
    },
    fetchUser() {
      dispatch(fetchUser());
    },
    saveDelta(delta) {
      dispatch(saveDelta(delta));
    }
  })
)(SupervisorMenu);
