/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import './Statistics.scss';
import Tooltip from './Tooltip';

export default class Statistics extends PureComponent {
  constructor() {
    super();

    this.state = {
      showCrunchHoursTooltip: false,
      showHolidaysTooltip: false,
      showOverworkTooltip: false
    };
  }

  // This needs to be curried since our linter doesn't like arrows...
  toggleTargetTooltip(t) {
    return () => {
      const target = `show${t}Tooltip`;
      const newState = !this.state[target];

      this.setState({
        [target]: newState
      });
    };
  }

  render() {
    const {
      crunchHours,
      holidays,
      additionalHolidays,
      longCareerHolidays,
      plannedCrunchHours,
      overworkHours
    } = this.props;
    let holidaysText = `${holidays} days`;
    if (holidays === 1) {
      holidaysText = `${holidays} day`;
    }

    let additionalHolidaysText = `${additionalHolidays} days`;
    if (additionalHolidays === 1) {
      additionalHolidaysText = `${additionalHolidays} day`;
    }

    let longCareerHolidaysText = `${longCareerHolidays} days`;
    if (longCareerHolidays === 1) {
      longCareerHolidaysText = `${longCareerHolidays} day`;
    }

    const totalHolidaysLeft =
      holidays + additionalHolidays + longCareerHolidays;

    const overworkDetails = overworkHours
      .entrySeq()
      .map(([percentage, value]) => tooltipRow(`${percentage}%`, `${value} h`));
    const totalOverworkHours = overworkHours
      .valueSeq()
      .reduce((a, b) => a + b, 0);
    const realTimeCrunches = crunchHours + totalOverworkHours;

    const crunchHoursText =
      realTimeCrunches > 0 ? `+${realTimeCrunches} h` : `${realTimeCrunches} h`;

    const tooltipRow = (header, value) => (
      <div key={header} style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ flex: 1, textAlign: 'right' }}>{header}:</div>
        <div style={{ width: '5px' }} />
        <div style={{ flex: 1, textAlign: 'left' }}>{value}</div>
      </div>
    );

    const isOverworks = overworkHours.valueSeq().some((v) => v !== 0);

    return (
      <div className="statistics">
        <div
          className="statistics-block"
          onMouseEnter={this.toggleTargetTooltip('CrunchHours')}
          onMouseLeave={this.toggleTargetTooltip('CrunchHours')}
          onTouchStart={this.toggleTargetTooltip('CrunchHours')}
        >
          <i className="fa fa-hourglass-end" />
          <div className="statistics-metric">
            <span>{crunchHoursText}</span>
            <Tooltip show={this.state.showCrunchHoursTooltip}>
              <div style={{ fontSize: '1.1em', lineHeight: '1.5' }}>
                {tooltipRow('flex', `${crunchHours} h`)}
                {isOverworks && overworkDetails}
                <div
                  style={{
                    borderBottom: '1px solid',
                    margin: '0.5em'
                  }}
                />
                After planned leave
                <div>{`${realTimeCrunches - plannedCrunchHours} h`}</div>
              </div>
            </Tooltip>
          </div>
        </div>

        <div
          className="statistics-block"
          onMouseEnter={this.toggleTargetTooltip('Holidays')}
          onMouseLeave={this.toggleTargetTooltip('Holidays')}
          onTouchStart={this.toggleTargetTooltip('Holidays')}
        >
          <i className="fa fa-plane" />
          <div className="statistics-metric">
            <span>Holidays left: {totalHolidaysLeft}</span>
            <Tooltip show={this.state.showHolidaysTooltip}>
              <p>Annual: {holidaysText}</p>
              <p>Additional: {additionalHolidaysText}</p>
              <p>Long career: {longCareerHolidaysText}</p>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}

Statistics.propTypes = {
  overworkHours: PropTypes.instanceOf(Map),
  crunchHours: PropTypes.number.isRequired,
  holidays: PropTypes.number.isRequired,
  additionalHolidays: PropTypes.number.isRequired,
  longCareerHolidays: PropTypes.number.isRequired,
  plannedCrunchHours: PropTypes.number.isRequired
};
