import React from 'react';

export default class ReadOnlyInput extends React.Component {
  focus() {
    this.input.focus();
  }

  render() {
    return (
      <div className="date-input-wrapper">
        <input readOnly ref={(el) => (this.input = el)} {...this.props} />
      </div>
    );
  }
}
