import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const IncludeSubordinates = ({
  handleSubordinatesChange,
  subordinateFilterValue
}) => {
  return (
    <div>
      <input
        id="includeSubordinates"
        type="checkbox"
        onChange={handleSubordinatesChange}
        checked={subordinateFilterValue}
      />
      <label htmlFor="includeSubordinates" className="searchbar--label">
        Show only me and my subordinates
      </label>
    </div>
  );
};

IncludeSubordinates.propTypes = {
  handleSubordinatesChange: PropTypes.func.isRequired,
  subordinateFilterValue: PropTypes.bool.isRequired
};

export default IncludeSubordinates;
