import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import IncludeSubordinates from '../IncludeSubordinates';
import SearchBarHeader from './SearchBarHeader';
import ShowOnlyAbsences from './ShowOnlyAbsences';
import HideReasons from './HideReasons';

const SearchBar = ({
  inputValue,
  handleSearchValueChange,
  handleSubordinatesChange,
  handleAbsenceFilterChange,
  subordinateFilterValue,
  hideReasons,
  handleHideReasonsChange,
  isSupervisor,
  showOnlyAbsences
}) => {
  return (
    <div className="searchbar--container">
      <label htmlFor="text-filter" style={{}}>
        <SearchBarHeader />
      </label>
      <input
        id="text-filter"
        value={inputValue}
        onChange={handleSearchValueChange}
        type="text"
        className="searchbar--input-field"
        placeholder="Alice, Bob"
      />

      {isSupervisor && (
        <IncludeSubordinates
          handleSubordinatesChange={handleSubordinatesChange}
          subordinateFilterValue={subordinateFilterValue}
        />
      )}

      <ShowOnlyAbsences
        showOnlyAbsences={showOnlyAbsences}
        handleAbsenceFilterChange={handleAbsenceFilterChange}
      />

      <HideReasons
        hideReasons={hideReasons}
        handleHideReasonsChange={handleHideReasonsChange}
      />
    </div>
  );
};

SearchBar.propTypes = {
  handleHideReasonsChange: PropTypes.func.isRequired,
  hideReasons: PropTypes.bool.isRequired,
  handleSearchValueChange: PropTypes.func.isRequired,
  handleSubordinatesChange: PropTypes.func.isRequired,
  handleAbsenceFilterChange: PropTypes.func.isRequired,
  inputValue: PropTypes.string,
  subordinateFilterValue: PropTypes.bool.isRequired,
  isSupervisor: PropTypes.bool.isRequired,
  showOnlyAbsences: PropTypes.bool.isRequired
};

SearchBar.defaultProps = {
  inputValue: ''
};

export default SearchBar;
