/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import { fromJS } from 'immutable';
import { loop, Effects } from 'redux-loop';
import { updateTeamMember } from './teamMembers';

import * as api from './api';

const ACTION_TYPES = {
  CLEAR_BALANCE_CHANGE: 'CLEAR_BALANCE_CHANGE',
  SAVE_DELTA: 'SAVE_DELTA'
};

export function clearBalanceChange() {
  return {
    type: ACTION_TYPES.CLEAR_BALANCE_CHANGE
  };
}

export function saveDelta(delta) {
  return {
    type: ACTION_TYPES.SAVE_DELTA,
    payload: {
      delta
    }
  };
}

const initialState = fromJS({
  fetching: false,
  fetchError: ''
});

export default function balanceChangeReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case ACTION_TYPES.CLEAR_BALANCE_CHANGE: {
      return state.delete('data');
    }
    case api.RESULTS.SAVE_DELTA_SUCCESS: {
      const data = fromJS(action.payload);
      return loop(
        state.set('fetching', false).set('fetchError', '').set('data', data),
        Effects.constant(updateTeamMember(data))
      );
    }
    case api.RESULTS.SAVE_DELTA_ERROR: {
      return state
        .set('fetching', false)
        .set('fetchError', fromJS(action.payload));
    }

    case ACTION_TYPES.SAVE_DELTA: {
      return loop(
        state.set('fetching', true),
        Effects.promise(api.saveDelta, action.payload.delta)
      );
    }
    default: {
      return state;
    }
  }
}
