/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import { fromJS } from 'immutable';
import { loop, Effects } from 'redux-loop';

import * as api from './api';

const ACTION_TYPES = {
  FETCH_USER: 'FETCH_USER',
  UPDATE_USER: 'UPDATE_USER'
};

export function fetchUser() {
  return {
    type: ACTION_TYPES.FETCH_USER
  };
}

export function updateUser(user) {
  return {
    type: ACTION_TYPES.UPDATE_USER,
    payload: {
      user
    }
  };
}

const initialState = fromJS({
  fetching: false,
  fetchError: ''
});

export default function userReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_USER: {
      return loop(state.set('fetching', true), Effects.promise(api.fetchUser));
    }
    case api.RESULTS.FETCH_USER_SUCCESS: {
      return state
        .set('fetching', false)
        .set('fetchError', '')
        .set('data', fromJS(action.payload));
    }
    case api.RESULTS.FETCH_USER_ERROR: {
      return state
        .set('fetching', false)
        .set('fetchError', fromJS(action.payload));
    }
    case ACTION_TYPES.UPDATE_USER: {
      return loop(
        state.set('fetching', true),
        Effects.promise(api.updateUser, action.payload.user)
      );
    }
    case api.RESULTS.UPDATE_USER_SUCCESS: {
      return state
        .set('fetching', false)
        .set('fetchError', '')
        .set('data', fromJS(action.payload));
    }
    case api.RESULTS.UPDATE_USER_ERROR: {
      return state
        .set('fetching', false)
        .set('fetchError', fromJS(action.payload));
    }
    default: {
      return state;
    }
  }
}
