import React from 'react';
import PropTypes from 'prop-types';

const ShowOnlyAbsences = ({ handleAbsenceFilterChange, showOnlyAbsences }) => {
  return (
    <div>
      <input
        id="showAbsences"
        type="checkbox"
        onChange={handleAbsenceFilterChange}
        checked={showOnlyAbsences}
      />
      <label htmlFor="showAbsences" className="searchbar--label">
        Show only people with absences
      </label>
    </div>
  );
};

ShowOnlyAbsences.propTypes = {
  handleAbsenceFilterChange: PropTypes.func.isRequired,
  showOnlyAbsences: PropTypes.bool
};

export default ShowOnlyAbsences;
