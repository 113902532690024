import React from 'react';
import './Switch.scss';

const Switch = ({ checked, onChange, date }) => {
  const id = `react-switch-${date}`;

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
      <input
        className="react-switch-checkbox"
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <label
        style={{ background: checked && 'rgb(52, 107, 119)' }}
        className="react-switch-label"
        htmlFor={id}
      >
        <span className="react-switch-button" />
      </label>
    </div>
  );
};

export default Switch;
