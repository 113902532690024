/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import Select2 from 'react-select2-wrapper';
import { Map, List } from 'immutable';
import onClickOutside from 'react-onclickoutside';
import InformationTooltip from './InformationTooltip';

import './Preferences.scss';

class Preferences extends PureComponent {
  constructor(props) {
    super(props);
    this.updateUser = this.updateUser.bind(this);
    this.toggleAutoEntry = this.toggleAutoEntry.bind(this);
    this.setUseHoursInput = this.setUseHoursInput.bind(this);
    this.setDefaultProject = this.setDefaultProject.bind(this);
    this.state = {
      useAutoEntry: this.props.user.getIn(['data', 'useAutoEntry']),
      useHoursInput: this.props.user.getIn(['data', 'useHoursInput']),
      defaultProject: this.props.user.getIn(['data', 'defaultProject']),
      modalIsOpen: false
    };
  }

  updateUser() {
    const userObject = this.props.user
      .getIn(['data'])
      .set('useAutoEntry', this.state.useAutoEntry)
      .set('useHoursInput', this.state.useHoursInput)
      .set('defaultProject', this.state.defaultProject)
      .toObject();

    this.props.updateUser(userObject);
  }

  handleClickOutside() {
    this.props.hide();
  }

  toggleAutoEntry() {
    this.setState({ useAutoEntry: !this.state.useAutoEntry }, () => {
      this.updateUser();
    });
  }

  setUseHoursInput(event) {
    const bool = event.target.value === 'true';
    this.setState({ useHoursInput: bool }, () => {
      this.updateUser();
    });
  }

  setDefaultProject(event) {
    const projectId = parseFloat(event.target.value);

    const newProject = this.props.projects.find(
      (project) => project.get('id') === projectId
    );

    this.setState({ defaultProject: newProject }, () => {
      this.updateUser();
    });
  }

  render() {
    const tooltip = (
      <div className="preferences-tooltip">
        <div className="preference">
          <label className="title">
            Automatic entries{' '}
            <InformationTooltip>
              <div>
                If you want to, you can also turn off the automatic entries.
                Then you need to remember to add the actual working hours for
                every day.
              </div>
            </InformationTooltip>
          </label>
          <span className="option">
            <input
              type="checkbox"
              checked={this.state.useAutoEntry}
              onChange={this.toggleAutoEntry}
            />{' '}
            Enabled
          </span>
        </div>
        <div className="divider" />
        <div className="preference">
          <label className="title">
            Time input method{' '}
            <InformationTooltip>
              <div>
                <h4>By hours</h4>
                Mark the time when you start work and finish work. Lunch is
                taken automatically into consideration when working longer than
                6 hours per day.
              </div>
              <div>
                <h4>By times</h4>
                Mark the total amount of hours you have worked per day (for
                example 9.25 h, 5.00 h etc.). Please remember to deduct the
                length of your lunch break from the total hours.
              </div>
            </InformationTooltip>
          </label>
          <span className="option">
            <input
              type="radio"
              name="by-hour"
              id="by-hour"
              onChange={this.setUseHoursInput}
              value="true"
              checked={this.state.useHoursInput}
            />{' '}
            <label htmlFor="by-hour">By hours</label>
          </span>
          <span className="option">
            <input
              type="radio"
              name="by-time"
              id="by-time"
              onChange={this.setUseHoursInput}
              value="false"
              checked={!this.state.useHoursInput}
            />{' '}
            <label htmlFor="by-time">By start time / end time</label>
          </span>
        </div>
        {/* <div className="divider" />
        <div className="preference">
          <label className="title">
            Default Project
            <InformationTooltip>
              <div>
                <h4>Default project</h4>
                Default project is used for automatic entries and as the default
                option when making an entry.
              </div>
            </InformationTooltip>
          </label>
          <span className="option">
            <Select2
              className="default-project-selector"
              value={this.state.defaultProject.get('id')}
              data={projectOptions}
              onChange={this.setDefaultProject}
            />
          </span>
        </div> */}
      </div>
    );

    return tooltip;
  }
}

Preferences.propTypes = {
  user: PropTypes.instanceOf(Map).isRequired,
  updateUser: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  projects: PropTypes.instanceOf(List)
};

export default onClickOutside(Preferences);
