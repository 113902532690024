/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Map, List } from 'immutable';

import './User.scss';
import Preferences from './Preferences';

export default class User extends PureComponent {
  constructor() {
    super();

    this.showPreferencesTooltip = this.showPreferencesTooltip.bind(this);
    this.hidePreferencesTooltip = this.hidePreferencesTooltip.bind(this);

    this.state = {
      showPreferencesTooltip: false,
      modalIsOpen: false
    };
  }

  showPreferencesTooltip(e) {
    e.preventDefault();
    this.setState({
      showPreferencesTooltip: !this.state.showPreferencesTooltip
    });
  }

  hidePreferencesTooltip() {
    this.setState({
      showPreferencesTooltip: false
    });
  }

  render() {
    const firstName = this.props.user.getIn(['data', 'firstName'], '');
    const profilePicture = this.props.user.getIn(
      ['data', 'profilePicture'],
      ''
    );

    return (
      <div className="user-container">
        <span>{firstName}</span>
        {this.state.showPreferencesTooltip ? (
          <Preferences
            hide={this.hidePreferencesTooltip}
            user={this.props.user}
            updateUser={this.props.updateUser}
            projects={this.props.projects}
          />
        ) : null}
        <div
          onClick={this.showPreferencesTooltip}
          className="profile-picture ignore-react-onclickoutside"
          style={{
            backgroundImage: `url('${profilePicture}')`,
            visibility: profilePicture !== '' ? 'visible' : 'hidden'
          }}
        />
      </div>
    );
  }
}

User.propTypes = {
  user: PropTypes.instanceOf(Map).isRequired,
  updateUser: PropTypes.func.isRequired,
  projects: PropTypes.instanceOf(List)
};
