/* eslint-disable prefer-promise-reject-errors */
/**
 * TimeEgg Copyright 2021 Remedy Entertainment Oyj – All rights reserved.
 *
 * TimeEgg is a software program produced and fully owned by Remedy Entertainment Oyj
 * (with the exception of the files specified below). Any and all access to the program
 * is given on an “AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND.
 *
 * TimeEgg is contains files which are a part of hours-ui, originally developed by Futurice Oy.
 *
 * Hours-ui is licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * hese files except in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import Promise from 'bluebird';

// axios used in mass holiday edit import because fetch was unable to
// send the file for some reason
import axios from 'axios';

export const RESULTS = {
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_ERROR: 'FETCH_USER_ERROR',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',
  SAVE_DELTA_SUCCESS: 'SAVE_DELTA_SUCCESS',
  SAVE_DELTA_ERROR: 'SAVE_DELTA_ERROR',
  FETCH_HOURS_SUCCESS: 'FETCH_HOURS_SUCCESS',
  FETCH_HOURS_ERROR: 'FETCH_HOURS_ERROR',
  FETCH_FUTURE_HOURS_SUCCESS: 'FETCH_FUTURE_HOURS_SUCCESS',
  FETCH_FUTURE_HOURS_ERROR: 'FETCH_FUTURE_HOURS_ERROR',
  FETCH_PAST_HOURS_SUCCESS: 'FETCH_PAST_HOURS_SUCCESS',
  FETCH_PAST_HOURS_ERROR: 'FETCH_PAST_HOURS_ERROR',
  SAVE_ENTRIES_SUCCESS: 'SAVE_ENTRIES_SUCCESS',
  SAVE_ENTRIES_ERROR: 'SAVE_ENTRIES_ERROR',
  SAVE_ENTRY: 'SAVE_ENTRY',
  SAVE_ENTRY_SUCCESS: 'SAVE_ENTRY_SUCCESS',
  SAVE_ENTRY_ERROR: 'SAVE_ENTRY_ERROR',
  FETCH_HOLIDAYS_SUCCESS: 'FETCH_HOLIDAYS_SUCCESS',
  FETCH_HOLIDAYS_ERROR: 'FETCH_HOLIDAYS_ERROR',
  UPLOAD_HOLIDAYS_SUCCESS: 'UPLOAD_HOLIDAYS_SUCCESS',
  UPLOAD_HOLIDAYS_ERROR: 'UPLOAD_HOLIDAYS_ERROR',
  UPLOAD_HOURS_SUCCESS: 'UPLOAD_HOURS_SUCCESS',
  UPLOAD_HOURS_ERROR: 'UPLOAD_HOURS_ERROR',
  UPLOAD_OVERTIME_DELTAS_SUCCESS: 'UPLOAD_OVERTIME_DELTAS_SUCCESS',
  UPLOAD_OVERTIME_DELTAS_ERROR: 'UPLOAD_OVERTIME_DELTAS_ERROR',
  UPLOAD_OVERWORK_REPORT_SUCCESS: 'UPLOAD_OVERWORK_REPORT_SUCCESS',
  UPLOAD_OVERWORK_REPORT_ERROR: 'UPLOAD_OVERWORK_REPORT_ERROR',
  FETCH_TEAM_MEMBERS_SUCCESS: 'FETCH_TEAM_MEMBERS_SUCCESS',
  FETCH_TEAM_MEMBERS_ERROR: 'FETCH_TEAM_MEMBERS_ERROR'
};

const apiUrl = 'api';
const apiVersion = '/v1';
const baseUrl = apiUrl + apiVersion;

export function apiRequest(method, path, body) {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json' /* ,
      'X_FORWARDED_USER': 'hr@remedy.fi' */
    }
  };
  if (body) {
    options.body = JSON.stringify(body);
  }
  options.credentials = 'include';
  return fetch(baseUrl + path + window.location.search, options).then(
    (response) => {
      if (response.ok) {
        return response.json();
      }
      return response
        .json()
        .catch(() =>
          Promise.reject({
            status: response.status,
            statusText: response.statusText,
            error: response.statusText
          })
        )
        .then((json) =>
          Promise.reject({
            status: response.status,
            statusText: response.statusText,
            error: json.error
          })
        );
    }
  );
}

export function fetchUser() {
  return apiRequest('GET', '/user/')
    .then((json) => {
      return {
        type: RESULTS.FETCH_USER_SUCCESS,
        payload: json
      };
    })
    .catch((err) => {
      if (err && err.status === 403) {
        window.location.replace(
          process.env.NODE_ENV === 'development'
            ? 'https://localhost:5001/api/v1/saml/?sso'
            : '/api/v1/saml/?sso'
        );
      }

      if (err && err.status === 401) {
        window.location.replace(
          process.env.NODE_ENV === 'development'
            ? 'https://localhost:5001/api/v1/saml/?slo'
            : '/api/v1/saml/?slo'
        );
      }

      return {
        type: RESULTS.FETCH_USER_ERROR,
        payload: err
      };
    });
}

export function fetchTeamMembers() {
  return apiRequest('GET', '/team_members/')
    .then((json) => {
      return {
        type: RESULTS.FETCH_TEAM_MEMBERS_SUCCESS,
        payload: json
      };
    })
    .catch((err) => {
      return {
        type: RESULTS.FETCH_TEAM_MEMBERS_ERROR,
        payload: err
      };
    });
}

export function updateUser(user) {
  return apiRequest('PUT', '/user/', user)
    .then((json) => {
      return {
        type: RESULTS.UPDATE_USER_SUCCESS,
        payload: json
      };
    })
    .catch((err) => {
      return {
        type: RESULTS.UPDATE_USER_ERROR,
        payload: err
      };
    });
}

function getHours(startDate, endDate, successType, errorType) {
  return apiRequest('GET', `/hours/${startDate}/${endDate}/`)
    .then((json) => {
      return {
        type: successType,
        payload: json
      };
    })
    .catch((err) => {
      return {
        type: errorType,
        payload: err
      };
    });
}

export function fetchHolidays(startDate, endDate) {
  return apiRequest('GET', `/holidays/${startDate}/${endDate}/`)
    .then((json) => {
      return {
        type: RESULTS.FETCH_HOLIDAYS_SUCCESS,
        payload: json
      };
    })
    .catch((err) => {
      return {
        type: RESULTS.FETCH_HOLIDAYS_ERROR,
        payload: err
      };
    });
}

function uploadRequest(file, url) {
  return axios
    .post(`${baseUrl}${url}`, file)
    .then((response) => response)
    .catch(({ response }) => {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        error: response.data.error
      });
    });
}

export function uploadHolidays(file) {
  return uploadRequest(file, '/import_holidays')
    .then(() => {
      return {
        type: RESULTS.UPLOAD_HOLIDAYS_SUCCESS
      };
    })
    .catch((e) => {
      return {
        type: RESULTS.UPLOAD_HOLIDAYS_ERROR,
        payload: e
      };
    });
}

export function uploadHours(file) {
  return uploadRequest(file, '/import_crunch_hours')
    .then(() => {
      return {
        type: RESULTS.UPLOAD_HOURS_SUCCESS
      };
    })
    .catch((e) => {
      return {
        type: RESULTS.UPLOAD_HOURS_ERROR,
        payload: e
      };
    });
}

export function uploadOvertimeDeltas(file) {
  return uploadRequest(file, '/import_overtime_deltas')
    .then(() => {
      return {
        type: RESULTS.UPLOAD_OVERTIME_DELTAS_SUCCESS
      };
    })
    .catch((e) => {
      return {
        type: RESULTS.UPLOAD_OVERTIME_DELTAS_ERROR,
        payload: e
      };
    });
}

export function uploadOverworkReport(file) {
  return uploadRequest(file, '/import_overwork_report')
    .then(() => {
      return {
        type: RESULTS.UPLOAD_OVERWORK_REPORT_SUCCESS
      };
    })
    .catch((e) => {
      return {
        type: RESULTS.UPLOAD_OVERWORK_REPORT_ERROR,
        payload: e
      };
    });
}

export function saveDelta(delta) {
  return apiRequest('POST', `/user/${delta.userId}/delta`, delta)
    .then((json) => {
      return {
        type: RESULTS.SAVE_DELTA_SUCCESS,
        payload: json
      };
    })
    .catch((err) => {
      return {
        type: RESULTS.SAVE_DELTA_ERROR,
        payload: err
      };
    });
}

export function fetchHours(startDate, endDate) {
  return getHours(
    startDate,
    endDate,
    RESULTS.FETCH_HOURS_SUCCESS,
    RESULTS.FETCH_HOURS_ERROR
  );
}

export function fetchFutureHours(startDate, endDate) {
  return getHours(
    startDate,
    endDate,
    RESULTS.FETCH_FUTURE_HOURS_SUCCESS,
    RESULTS.FETCH_FUTURE_HOURS_ERROR
  );
}

export function fetchPastHours(startDate, endDate) {
  return getHours(
    startDate,
    endDate,
    RESULTS.FETCH_PAST_HOURS_SUCCESS,
    RESULTS.FETCH_PAST_HOURS_ERROR
  );
}

let inSaveEntriesQueue = 0;

function waitWhile(conditionFn) {
  function wait(cb) {
    if (conditionFn()) {
      cb();
    } else {
      setTimeout(() => wait(cb), 200);
    }
  }

  return new Promise((resolve) => {
    wait(resolve);
  });
}

export function saveEntries(date, entries) {
  // Set entrys times as json in localtime, without timezone

  const localizeEntryTimes = (entry) => {
    if (entry.startTime && entry.endTime) {
      return {
        ...entry,
        endTime: entry.endTime.toJSONLocal(),
        startTime: entry.startTime.toJSONLocal()
      };
    }
    return { ...entry };
  };

  // Simple queue handling, the order of requests executing is random
  return (
    waitWhile(() => inSaveEntriesQueue === 0)
      .then(() => {
        inSaveEntriesQueue++;

        // Run only one request at a time, because the api returns global updated data,
        // so the last send request is always the last one to return and it holds the
        // most recent data

        // This method handles this error case (lower case = request, upper case = response, letter = day, number = entry request):
        // timeline: a b ==>
        // timeline: a1 a2 b1 b2 ==>
        // timeline: A2 B1 B2 A1  <-- A1 holds the oldest data, which would overwrite B2
        // This method:
        // timeline: A1 A2 B1 B2
        return Promise.mapSeries(entries, (entry) => {
          let request;
          if (entry.new) {
            if (entry.untilDate) {
              request = apiRequest('POST', '/entry/', {
                id: entry.id,
                new: true,
                projectId: entry.projectId,
                taskId: entry.taskId,
                description: entry.description,
                date: entry.date,
                endDate: entry.untilDate,
                // Custom hours absences can now be created
                // Add hours to the request if hours is present
                ...(entry.hours && { hours: entry.hours })
              });
            } else {
              const localizedEntry = localizeEntryTimes(entry);
              request = apiRequest('POST', '/entry/', {
                ...localizedEntry,
                date
              });
            }
          } else if (entry.deleted) {
            request = apiRequest('DELETE', `/entry/${entry.id}`);
          } else {
            const localizedEntry = localizeEntryTimes(entry);
            request = apiRequest('PUT', `/entry/${entry.id}`, {
              ...localizedEntry,
              date
            });
          }

          return request.catch((error) =>
            Promise.resolve({
              error,
              date,
              entry
            })
          );
        });
      })
      .then((results) => {
        let action;
        if (results.filter((result) => result.error).length > 0) {
          action = {
            type: RESULTS.SAVE_ENTRIES_ERROR,
            payload: {
              date,
              results
            }
          };
        } else {
          action = {
            type: RESULTS.SAVE_ENTRIES_SUCCESS,
            payload: {
              date,
              results
            }
          };
        }
        return Promise.resolve(action);
      })
      // Errors shouldn't happen because errors are caught in the above mapper.
      .catch((error) => {
        return {
          type: RESULTS.SAVE_ENTRIES_ERROR,
          payload: {
            date,
            error
          }
        };
      })
      .finally(() => inSaveEntriesQueue--)
  );
}
