import { useSelector } from 'react-redux';
import { string } from 'prop-types';

/**
 * Hook to fetch all entries in a date
 *
 * @param {string} date
 * @returns Date object
 */
const useDateHours = (date) => {
  const month = date.slice(0, 7);

  return useSelector((state) =>
    state.getIn(['hours', 'data', 'months', month, 'days', date])
  ).toJS();
};

useDateHours.propTypes = {
  date: string
};

export default useDateHours;
